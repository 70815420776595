export enum TimerMode {
    Timeout = 'timeout',
    Interval = 'interval',
}

interface ITimer {
  start: () => void
  stop: () => void
}

export const Timer = (mode: TimerMode, handler: () => void, time: number): ITimer => {
  type IntervalType = ReturnType<typeof setInterval>
  type TimeoutType = ReturnType<typeof setTimeout>
  let timerId: IntervalType | TimeoutType | undefined = undefined
  let start: () => void
  let stop: () => void

  switch (mode) {
    case TimerMode.Interval:
      start = () => {
        stop()
        timerId = setInterval(handler, time)
      }
      stop = () => {
        if (timerId) {
          clearInterval(timerId)
        }
        timerId = undefined
      }
      break

    case TimerMode.Timeout:
      start = () => {
        stop()
        timerId = setTimeout(handler, time)
      }
      stop = () => {
        if (timerId) {
          clearTimeout(timerId)
        }
        timerId = undefined
      }
      break

    default:
      throw new TypeError('Unsupported mode')
  }

  return { start, stop }
}
