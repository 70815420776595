export class Deferred<T> {
  private readonly _promise: Promise<T>
  private _resolve?: (val: T) => void
  private _reject?: (val: unknown) => void

  constructor() {
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve
      this._reject = reject
    })

    this.resolve = this.resolve.bind(this)
    this.reject = this.reject.bind(this)
    this.then = this.then.bind(this)
    this.catch = this.catch.bind(this)
    this.finally = this.finally.bind(this)
  }

  get promise(): Promise<T> {
    return this._promise
  }

  resolve(value: T): Promise<T> {
    if (this._resolve) this._resolve(value)
    return this.promise
  }

  reject(reason: unknown): Promise<T> {
    if (this._reject) this._reject(reason)
    return this.promise
  }

  then(...args: [(((reason: T) => void | PromiseLike<unknown>) | null | undefined)?]): Promise<unknown> {
    return Promise.prototype.then.apply(this.promise, args)
  }

  catch(...args: [(((reason: unknown) => void | PromiseLike<unknown>) | null | undefined)?]): Promise<unknown> {
    return Promise.prototype.catch.apply(this.promise, args)
  }

  finally(onFinally: [((() => void) | null | undefined)?]): Promise<unknown> {
    if ('finally' in Promise.prototype && typeof Promise.prototype.finally === 'function') {
      return Promise.prototype.finally.apply(this.promise, onFinally)
    }

    if (typeof onFinally !== 'function') {
      return Promise.prototype.then.call(
        this.promise,
        (x: T) => Promise.resolve(onFinally).then(() => x),
        (e: unknown) =>
          Promise.resolve(onFinally).then(() => {
            throw e
          })
      )
    }

    return Promise.prototype.then.call(
      this.promise,
      (x: T) => Promise.resolve((onFinally as () => void)()).then(() => x),
      (e: unknown) =>
        Promise.resolve((onFinally as () => void)()).then(() => {
          throw e
        })
    )
  }
}
