/**
 * ClientlessAuthTypes
 */

export type ResourceID = string;

/** @hidden */
export type ClientlessNetworkCallOptions = {
    path: string;
    method: string;
    headers?: Record<string, string>;
    searchParams?: Record<string, string>;
    body?: string | URLSearchParams | null;
};

/** @hidden */
export type ClientlessDeviceIdParams = {
    deviceId: string;
    deviceType: string;
};

/** @hidden */
export type ClientlessRegCodeRequestParams = ClientlessDeviceIdParams & {
    registrationURL?: string;
    ttl?: string;
};

/** @hidden */
export type ClientlessRegCodeResponseInfo = {
    deviceId: string; // "NzU0NDUxODYtQkFCMC00NDNELUI4NzYtODEwRjcxRjRDQUI2"
    deviceType: string; // "browser"
    deviceUser: string | null;
    appId: string; // "generate-regcode-via-web"
    appVersion: string; // "0.0.1"
    registrationURL: string | null;
    deviceInfo: string; // "eyJ0eXBlIjoiRGVza3R...FsU2l6ZSI6bnVsbH19"
    userAgent: string; // "Mozilla/5.0 ... Chrome/74.0.3712.0 Safari/537.36"
    connectionInfo: string; // "eyJpcEFkZHJlc3M...UeXBlIjpudWxsfQ=="
    originalUserAgent: string; // "Mozilla/5.0 ... Chrome/74.0.3712.0 Safari/537.36"
    normalizedDeviceType: string; // "unknown"
    authorizationType: string; // "DEV_APPS"
};

/** @hidden */
export type ClientlessRegCodeResponse = {
    id: string; // "31e2b4ae-bd34-4bd4-acff-b4d644ce09d8"
    code: string; // "H62CFFJ"
    requestor: string; // "AdultSwim"
    mvpd: string | null;
    generated: number; // 1550695405102
    expires: number; // 1550731405102
    info: ClientlessRegCodeResponseInfo;
};

/** @hidden */
export type ClientlessCheckRegcodeAuthNParams = {
    requestor: string;
};

/** @hidden */
export type ClientlessLogoutParams = ClientlessDeviceIdParams;

/** @hidden */
export type ClientlessPlatformSSOProps = {
    /** flag indicating whether this MVPD is integrated via Platform SSO */
    enablePlatformServices: boolean;
    /** should this MVPD appear in the platform picker */
    displayInPlatformPicker: boolean;
    /** the identifier of this MVPD as known by the platform */
    platformMappingId: string;
    /** the user metadata fields expected to be available on a successful login */
    requiredMetadataFields: string[];
};

/** @hidden */
export type ClientlessAdobeConfigMvpd = {
    id: string;
    displayName: string;
    logoUrl?: string; // "http://entitlement.auth.adobe.com/entitlement/noLogo.png"
    tempPass?: boolean;
    iFrameHeight?: number;
    iFrameRequired?: boolean;
    iFrameWidth?: number;
    isProxy?: true;
} & Partial<ClientlessPlatformSSOProps>;

/** @hidden */
export type ClientlessAdobeConfig = {
    mvpd: ClientlessAdobeConfigMvpd[];
};

/** @hidden */
export type ClientlessAuthenticateParams = {
    regCode: string;
    mvpdId: string;
    redirectUrl: string;
};

/** @hidden */
export type ClientlessAuthNToken = {
    expires: number; // Adobe returns a string, e.g. "1572641301000"
    mvpd: string | null; // "Cablevision"
    proxyMvpd?: string; // "sampleProxyMvpdId"
    requestor: string; // "TBS"
    userId: string; // "beca1329f62fe94b51dc415f70996b8b"
};

export function isClientlessAuthNToken(obj: unknown): obj is ClientlessAuthNToken {
  return (
    typeof obj === 'object' &&
        obj !== null &&
        typeof (obj as ClientlessAuthNToken).expires === 'string' &&
        typeof (obj as ClientlessAuthNToken).mvpd === 'string' &&
        typeof (obj as ClientlessAuthNToken).requestor === 'string' &&
        typeof (obj as ClientlessAuthNToken).userId === 'string'
  )
}

/** @hidden */
export type ClientlessAuthZToken = {
    expires: number; // Adobe returns a string, e.g. "1570142856000"
    mvpd: string; // "Cablevision"
    proxyMvpd?: string; // "sampleProxyMvpdId"
    requestor: string; // "TBS"
    resource: string; // "TBS"
};

export function isClientlessAuthZToken(obj: unknown): obj is ClientlessAuthZToken {
  return (
    typeof obj === 'object' &&
        obj !== null &&
        typeof (obj as ClientlessAuthZToken).expires === 'string' &&
        typeof (obj as ClientlessAuthZToken).mvpd === 'string' &&
        typeof (obj as ClientlessAuthZToken).requestor === 'string' &&
        typeof (obj as ClientlessAuthZToken).resource === 'string'
  )
}

/** @hidden */
export type ClientlessAuthZTokenError = {
    details: string; // "↵It appears that your Optimum subscription does not include HBO. HBO GO is available free to Optimum customers who subscribe to HBO. To sign up for HBO and start using HBO GO, please <a href="http://optimum.com/order/entry.jsp" target="_blank"><u>click here</u></a> or contact 866-200-7192.↵"
    message: string; // "noAuthz"
    status: string; // 403
};

/** @hidden */
export type ClientlessShortMediaToken = {
    expires: number; // Adobe returns a string, e.g. "1570107990000"
    mvpdId: string; // "Cablevision"
    requestor: string; // "TBS"
    resource: string; // "TBS"
    serializedToken: string; // "PHNpZ25hdHVyZUluZm8+Yjhpa0tGNWtpNzE3Y2c2d2o1QWFzNWNkWFpVdEhBeUVRYm9KWmlKYVVDV0QxMlJGVkZzRzlBLzBYUVdUcFh6RndtVmhtRFlIRXpNRmZWdERDVS9ES2FWU2w5QkFXdHFsbWVDK29yMVVHN0cxTjNvamhuMEgyL281K0poYjNyZlU2cnB4WW5mOHp3VXFLVzc2Z0oyUllIR1A5ZGpIOGVVRXpnZ1RnOFMzL2VRPTxzaWduYXR1cmVJbmZvPjxhdXRoVG9rZW4+PHNlc3Npb25HVUlEPmJlY2ExMzI5ZjYyZmU5NGI1MWRjNDE1ZjcwOTk2YjhiPC9zZXNzaW9uR1VJRD48cmVxdWVzdG9ySUQ+VEJTPC9yZXF1ZXN0b3JJRD48cmVzb3VyY2VJRD5UQlM8L3Jlc291cmNlSUQ+PHR0bD40MjAwMDA8L3R0bD48aXNzdWVUaW1lPjIwMTktMTAtMDMgMDU6NTk6MzAgLTA3MDA8L2lzc3VlVGltZT48bXZwZElkPkNhYmxldmlzaW9uPC9tdnBkSWQ+PC9hdXRoVG9rZW4+"
    userId: string; // "beca1329f62fe94b51dc415f70996b8b"
};

/** @hidden */
export type ClientlessShortMediaTokenError = {
    message: string; // "User not authorized"
    status: number; // 403
};

/** @hidden */
export type ClientlessUserMetadataMaxRating = Record<string, any> & {
    MPAA?: string; // "NR"
    VCHIP?: string; // "X"
    URL?: string; // "http://manage.my/parental"
};

/** @hidden */
export type ClientlessUserMetadataData = Record<string, any> & {
    allowMirroring?: boolean; // TODO: verify truly boolean and not strings
    channelID?: string[]; // list of channels the user is entitled to view
    encryptedZip?: string; // Comcast exposes the zip code encrypted
    hba_status?: boolean; // TODO: verify truly boolean
    householdID?: string; // "1o7241p" -- if the MVPD does not support subaccounts, same as `userID`
    inHome?: any; // undocumented
    is_hoh?: string; // "1" to identify head of household
    language?: any; // undocumented
    maxRating?: ClientlessUserMetadataMaxRating; // dictionary
    mvpd?: string; // undocumented -- "Cablevision"
    onNet?: any; // undocumented
    primaryOID?: string; // if `typeID` is "Primary", contains value of `userID`
    typeID?: string; // flag identifying if account is Primary or Secondary account
    upstreamUserID?: string; // "AQICHvQsgWLsFElFIQUnsS7QeAi6ZhRtBKLjHlj7xYcl866g3Bd%2FvguUQw%3D%3D"
    userID?: string; // "AQICHvQsgWLsFElFIQUnsS7QeAi6ZhRtBKLjHlj7xYcl866g3Bd%2FvguUQw%3D%3D"
    zip?: string[]; // ["77754", "12345"]
};

/** @hidden */
export type ClientlessUserMetadata = {
    data: ClientlessUserMetadataData;
    encrypted: string[]; // []
    state: string; // "PLAIN"
    updated: number; // 1570109086776
};

export function isClientlessUserMetadata(data: unknown): data is ClientlessUserMetadata {
  return (
    typeof data === 'object' &&
        data !== null &&
        typeof (data as ClientlessUserMetadata).data === 'object' &&
        (data as ClientlessUserMetadata).data !== null &&
        typeof (data as ClientlessUserMetadata).state === 'string' &&
        typeof (data as ClientlessUserMetadata).updated === 'number'
  )
}

/** @hidden */
export type ClientlessPreauthorizedResourceData = {
    id: ResourceID; // "TNT"
    authorized: boolean; // true
    error?: any;
};

/** @hidden */
export type ClientlessPreauthorizedResources = {
    resources: ClientlessPreauthorizedResourceData[];
};

/** @hidden */
export interface ClientRegistrationData {
    success: true;
    client_id: string;
    client_secret: string;
    client_id_issued_at: number;
    redirect_uris: string[];
    grant_types: ClientRegistrationGrantType[];
}

/** @hidden */
export type ClientRegistrationGrantType =
// Used by insecure clients, such as Android SDK
    'client_credentials';

/** @hidden */
export type ClientRegistrationErrorType =
    | 'invalid_request'
    | 'invalid_redirect_uri'
    | 'invalid_software_statement'
    | 'unapproved_software_statement';

/** @hidden */
export interface ClientRegistrationError {
    success: false;
    error: ClientRegistrationErrorType;
}

/** @hidden */
export interface ClientAccessTokenResponse {
    // value to use to call APIs
    access_token: string;
    // seconds until the access_token expires
    expires_in: number;
    // type of the token **bearer**
    token_type: string;
    // issue time of token
    created_at: number;
}

/**
 * Adobe AccessEnabler metadata keys, except for `DEVICE_ID`,
 * `AUTHENTICATED_TTL` and `AUTHORIZED_TTL` -- these 3 are special values that
 * are handled differently per platform by the AccessEnabler API.
 */
export enum MetadataKey {
    USER_ID = 'userID',
    ZIP = 'zip',
    MAX_RATING = 'maxRating',
    HOUSEHOLD_ID = 'householdID',
    CHANNEL_ID = 'channelID',
    HEAD_OF_HOUSEHOLD = 'is_hoh',
    TYPE_ID = 'typeID',
    PRIMARY_OID = 'primaryOID',
    LANGUAGE = 'language',
    UPSTREAM_USER_ID = 'upstreamUserID',
    HBA_STATUS = 'hba_status',
    ON_NET = 'onNet',
    IN_HOME = 'inHome',
    ALLOW_MIRRORING = 'allowMirroring',
    // The following are not Adobe keys yet MUST match the keys defined in iOS & Android for Nexus:
    DEVICE_ID = 'DEVICE_ID',
    AUTHENTICATED_TTL = 'AUTHENTICATED_TTL',
    AUTHORIZED_TTL = 'AUTHORIZED_TTL',
}

/** [[MetadataValue]] response to requests for `DEVICE_ID`, `AUTHENTICATED_TTL`, `AUTHORIZED_TTL` */
export type SimpleMetadataValue = string | null;

/** [[MetadataValue]] response to requests for user metadata */
export type UserMetadataValue = string | boolean | number | Record<string, unknown> | null;

/**
 * According to Adobe docs, the response metadata can be either "simple" (or
 * "static"), which is a string (or null), or "user metadata", which "can be
 * a primitive or JSON object"
 *
 * Source: http://tve.helpdocsonline.com/javascript-api-reference-v2$setMetaData
 *
 * @see {@link UserMetadataResponse.value}
 */
export type MetadataValue = SimpleMetadataValue | UserMetadataValue;

export interface PreauthorizationOptions {
    disableLocalCache?: boolean;
    disableRemoteCache?: boolean;
}

/**
 * A preauthorization result for an individual [[Channel]]. This can only be
 * obtained in the [[PreauthorizationResponse]] return from [[IAuth.preauthorize]].
 */
export interface PreauthorizationDecision {
    readonly id: string;
    readonly authorized: boolean;
    readonly error?: any;
}

export type TrackingMvpdId = string;
export type TrackingSuccess = boolean;
export type TrackingGUID = string;
export type TrackingTokenInCache = boolean;
export type TrackingDeviceType = string; // 'computer' | 'tablet' | 'mobile' | 'gameconsole' | 'unknown'
export type TrackingAccessEnablerType = string; // 'flash' | 'html5' | 'ios' | 'android'
export type TrackingOS = string;

export type MvpdSelectionTrackingData = [TrackingMvpdId, TrackingDeviceType, TrackingAccessEnablerType, TrackingOS];

export type AuthenticationTrackingData = [
    TrackingSuccess,
    TrackingMvpdId,
    TrackingGUID,
    TrackingTokenInCache,
    TrackingDeviceType,
    TrackingAccessEnablerType,
    TrackingOS
];

export type AuthorizationTrackingData = [
    TrackingSuccess,
    TrackingMvpdId,
    TrackingGUID,
    TrackingTokenInCache,
    TrackingDeviceType,
    TrackingAccessEnablerType,
    TrackingOS
];

export type TrackingData = MvpdSelectionTrackingData | AuthenticationTrackingData | AuthorizationTrackingData;

export enum TrackingDataEventType {
    MVPD_SELECTION = 'mvpdSelection',
    AUTHENTICATION_DETECTION = 'authenticationDetection',
    AUTHORIZATION_DETECTION = 'authorizationDetection',
}
