import { MetadataKey, MetadataValue, PreauthorizationDecision, } from './ClientlessAuthTypes'
import { Provider } from './MVPDConfigAPI'

/**
 * AuthEngineResponse
 */
export interface BaseEventResult<E> {
    type: E;

    [x: string]: any;
}

export type EventResult<E> = BaseEventResult<E>;

/** @hidden */
export enum AuthEngineResponseType {
    Did_Set_Requestor_Complete = 'didSetRequestorComplete',
    Did_Set_Authentication_Status = 'didSetAuthenticationStatus',
    Did_Receive_Selected_Provider = 'didReceiveSelectedProvider',
    Did_Set_Token = 'didSetToken',
    Did_Receive_Preauthorized_Resources = 'didReceivePreauthorizedResources',
    Did_Set_Metadata_Status = 'didSetMetadataStatus',
}

/** @hidden */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BaseAuthEngineResponse extends EventResult<AuthEngineResponseType> {
}

/**
 * Auth Engine Set Requestor Response
 *
 * The value returned from successful calls to `IAuthEngine.setRequestor`
 *
 * @hidden
 */
export interface SetRequestorResponse extends BaseAuthEngineResponse {
    readonly status: boolean;
}

/**
 * Authentication Status Response
 *
 * The value returned from successful calls to:
 *   - `fetchAuthContext()`
 *   - `login()`
 *   - `logout()`
 *   - `handleRedirectURL()`
 */
export interface AuthStatusResponse extends BaseAuthEngineResponse {
    readonly isAuthenticated: boolean;
    readonly info: string;
    readonly adobeHashId?: string;
    readonly provider?: Provider;
}

/**
 * Auth Engine Get Selected Provider Response
 *
 * The value returned from successful calls to `IAuthEngine.getSelectedProvider`
 *
 * @hidden
 */
export interface GetSelectedProviderResponse extends BaseAuthEngineResponse {
    readonly provider?: Provider;
}

/**
 * Auth Engine Authorization Data Response
 *
 * The value returned from successful calls to `IAuthEngine.authorize`
 *
 * @hidden
 */
export interface AuthorizationDataResponse extends BaseAuthEngineResponse {
    readonly channel: string;
    readonly token: string;
}

/**
 * Auth Engine Preauthorize Data Response
 *
 * The value returned from successful calls to `IAuthEngine.preauthorize`
 *
 * @hidden
 */
export interface PreauthorizationDataResponse extends BaseAuthEngineResponse {
    readonly decisions: PreauthorizationDecision[];
}

/**
 * Auth Engine Metadata Response
 *
 * The value returned from successful calls to `IAuthEngine.fetchMetadata`
 *
 * @hidden
 */
export interface MetadataDataResponse extends BaseAuthEngineResponse {
    readonly key: MetadataKey;
    args?: Record<string, string>;
    encrypted: boolean;
    readonly value: MetadataValue;
}

export type AuthEngineResponse =
    | SetRequestorResponse
    | AuthStatusResponse
    | AuthorizationDataResponse
    | PreauthorizationDataResponse
    | GetSelectedProviderResponse
    | MetadataDataResponse;
