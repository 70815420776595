/**
 * AuthError
 */

/**
 * An error dictionary returned from Auth APIs with additional props
 */
export interface AuthError {
    /**
     * [[AuthErrorCode]] a string uniquely identifying the source of the error
     */
    code: AuthErrorCode;
    /**
     * [[AuthErrorSeverity]] how bad the error is
     */
    severity: AuthErrorSeverity;
    /**
     * {string} describing the error
     */
    message: string;
    /**
     * [[AuthErrorMetadata]] a hash/map/dictionary of additional key/values
     */
    metadata?: AuthErrorMetadata;
}

export const isAuthError = (error: unknown): error is AuthError => {
  const authError = error as AuthError
  return (
    authError != null &&
        typeof authError === 'object' &&
        'code' in authError &&
        'severity' in authError &&
        'message' in authError
  )
}

/** The AuthErrorSeverity of the [[AuthError]] */
export enum AuthErrorSeverity {
    /**
     * This error is potentially recoverable by retrying. For example,
     * if the network is down, the client may attempt to fetch the data again once the network is available.
     */
    Error = 'error',
    /**
     * This error is purely informative. The Auth lifecycle can likely continue,
     * although with potential caveats or risks.
     */
    Warn = 'warn',
    /**
     * Errors of this nature always represent irrecoverable errors.
     * The Auth SDK must be torn down and authentication cannot be attempted again without user input.
     */
    Fatal = 'fatal',
}

/**
 * `AuthErrorCategory` = what was going on when the error occurred
 * @hidden
 */
export enum AuthErrorCategory {
    Unknown = 'UN',
    Initialization = 'IT',
    Config = 'CF',
    Authentication = 'AN',
    Authorization = 'AZ',
    Preauthorization = 'PA',
    Metadata = 'MD',
    Logout = 'LO',
}

/**
 * `AuthErrorPlatform` = where the error occurred
 * @hidden
 */
export enum AuthErrorPlatform {
    Unknown = '00',
    Web = '01',
    iOS = '02',
    Android = '03',
    ReactNative = '04',
}

/**
 * `AuthErrorDomain` = who to blame for the error
 * @hidden
 */
export enum AuthErrorDomain {
    Unknown = 'UN',
    Target = 'TA',
    Shared = 'SH',
    NativeService = 'NS',
    Nexus = 'NX',
    Adobe = 'AE',
    Runtime = 'RT',
}

/**
 * `AuthErrorNumeral` = uniquely identify the cause of the failure
 * @hidden
 * TODO: sync with iOS: AuthBlock/Model/AuthError.swift
 * TODO: sync with Android: com/turner/top/auth/model/AuthError.kt
 */
export enum AuthErrorNumeral {
    Unspecified = '0000',

    /** Constructing or making the request for MVPD Config failed */
    MvpdConfigRequestFailure = '0100',

    /** Failed to obtain or process the response from MVPD Config */
    MvpdConfigResponseFailure = '0101',

    /**
     * If you attempt to select a Provider not enabled in the MVPD Config.
     * It is also possible to be authN'ed passively via SSO with a provider not in the MVPD Config.
     */
    MvpdConfigProviderNotFound = '0102',

    /** iOS: `buildImageURL()` failure: invalid URL */
    UnparseableImageURL = '0111',

    /** iOS: `buildImageURL()` failure: network error */
    UnreachableImageURL = '0112',

    /** iOS: `buildImageURL()` failure: could not build image */
    UnqualifiedImageURL = '0113',

    /** Uncaught `Error` thrown during `prepare()` */
    AdobeInitializationFailure = '0200',

    /** If you use an invalid software statement or an invalid requestor ID */
    AdobeSetRequestorFailure = '0201',

    /** Uncaught `Error` thrown during `fetchAuthContext()` or `login()` */
    AdobeAuthenticationFailure = '0202',

    /** In the unlikely event that AccessEnabler still says you're authenticated post-`logout()` */
    AdobeLogoutFailure = '0203',

    /** In the unlikely event that AccessEnabler provides an empty response */
    AdobeAuthorizationMissingData = '0207',

    /** In the unlikely event that AccessEnabler responds with a different schema */
    AdobeAuthorizationInvalidData = '0208',

    /** If you attempt to `cancelAuthentication()` when login procedure is not in progress */
    CallOutsideOfAuthenticationFlow = '0400',

    /**
     * Scenarios with this response:
     * - if you attempt to `logout()` when you're not logged in
     * - if you attempt to `fetchUserMetadata()` when you're not logged in
     * - if you attempt to `authorize()` when you're not logged in
     */
    NotAuthenticated = '0401',

    /** If you are still authenticated in system settings */
    AuthenticatedInSystemSettings = '0402',

    /** iOS: exceeded maxAuthenticationPollAttempts */
    AuthenticationTimeout = '0403',

    /** If you attempt to use SSO in iOS Simulator */
    SsoUnavailableInSimulator = '0410',

    /** If you attempt to use SSO for a provider who doesn't support it */
    SsoUnsupportedByProvider = '0411',

    /** If your account with your Provider does not have authorization to watch the requested content */
    NotAuthorized = '0501',

    ClientlessUnknownError = '0600',
    ClientlessAPIUninitialized = '0601',
    ClientlessAPIBadRequest = '0602',
    ClientlessAPIUnexpectedResponseCode = '0603',
    ClientlessMissingSoftwareStatement = '0604',
    ClientlessClientRegistrationServiceException = '0605',
    ClientlessClientRegistrationInvalidResponseBody = '0606',
    ClientlessClientAccessTokenServiceException = '0607',
    ClientlessClientAccessTokenInvalidResponseBody = '0608',
    ClientlessMissingRequestorId = '0609',
    ClientlessConfigServiceException = '0610',
    ClientlessConfigInvalidResponseBody = '0611',
    ClientlessConfigParseError = '0612',

    ClientlessRegCodeServiceException = '0620',
    ClientlessRegCodeInvalidResponseBody = '0621',
    ClientlessRegCodeNotFound = '0622',

    ClientlessCheckAuthNServiceException = '0630',
    ClientlessCheckAuthNForbidden = '0631',
    ClientlessCheckAuthNCanceled = '0632',

    ClientlessAuthNTokenServiceException = '0634',
    ClientlessAuthNTokenParseError = '0635',
    ClientlessAuthNTokenNotFound = '0636',
    ClientlessAuthNTokenExpired = '0637',
    ClientlessInvalidClient = '0638',
    ClientlessAccessDenied = '0639',

    ClientlessAuthorizeServiceException = '0640',
    ClientlessAuthorizeParseError = '0641',
    ClientlessAuthorizeNotAuthorized = '0642',

    ClientlessAuthZTokenServiceException = '0644',
    ClientlessAuthZTokenParseError = '0645',
    ClientlessAuthZTokenNotAuthenticated = '0646',
    ClientlessAuthZTokenExpired = '0647',
    ClientlessAuthZTokenNotAuthorized = '0648',

    ClientlessShortMediaTokenServiceException = '0650',
    ClientlessShortMediaTokenParseError = '0651',
    ClientlessShortMediaTokenNotAuthorized = '0652',

    ClientlessPreauthorizeServiceException = '0654',
    ClientlessPreauthorizeNoResourcesProvided = '0655',
    ClientlessPreauthorizeNotAuthorized = '0656',
    ClientlessPreauthorizeExpired = '0657',
    ClientlessPreauthorizeNotAuthenticated = '0658',

    ClientlessUserMetadataServiceException = '0680',
    ClientlessUserMetadataParseError = '0681',
    ClientlessUserMetadataNotFound = '0682',
    ClientlessUserMetadataExpiredToken = '0683',

    /** Auth engine failed to init or has not been init'ed yet */
    InternalFailure = '0900',

    /** If you provide incorrect/invalid parameters to an Auth API */
    TypeError = '0901',

    /** Incapable of further communication */
    BridgeClosed = '0902',
}

/**
 * An alphanumeric string constructed of substrings representing
 * `[Category]` `[Platform]` `[Domain]` `[Numeral]`
 *
 * @see {@link AuthError.code}
 */
export type AuthErrorCode = string;

/**
 * The ErrorCodeBuilder normalizes the steps to building an [[AuthErrorCode]]
 * @hidden
 */
export class AuthErrorCodeBuilder {
  private _category: AuthErrorCategory
  private _platform: AuthErrorPlatform
  private _domain: AuthErrorDomain
  private _numeral: AuthErrorNumeral

  /**
     * Creates an instance of ErrorCodeBuilder.
     *
     * @param {AuthErrorCategory}_category   `[CF]10AE100`
     * @param {AuthErrorPlatform} _platform  `CF[10]AE100`
     * @param {AuthErrorDomain} _domain       `CF10[AE]100`
     * @param {AuthErrorNumeral} _numeral     `CF10AE[100]`
     */
  constructor(
    category: AuthErrorCategory,
    platform: AuthErrorPlatform,
    domain: AuthErrorDomain,
    numeral: AuthErrorNumeral
  ) {
    this._category = category
    this._platform = platform
    this._domain = domain
    this._numeral = numeral
  }

  /** Builds the [[AuthErrorCode]] that has been configured */
  build(): AuthErrorCode {
    return `${this._category}${this._platform}${this._domain}${this._numeral}`
  }
}

/** Optional additional information regarding the [[AuthError]] */
export interface AuthErrorMetadata {
    /**  A suggestion to recover if recoverable */
    recoverySuggestion?: string;

    /** A sub-service error code; this may have Adobe-specified AccessEnabler error codes */
    subErrorCode?: string;

    /** A sub-service error message; in the case of authZ failures, this could have a Provider-specified message */
    subErrorMessage?: string;
}

enum AuthErrorMetadataProperty {
    RecoverySuggestion = 'recoverySuggestion',
    SubErrorCode = 'subErrorCode',
    SubErrorMessage = 'subErrorMessage',
}

/**
 * A fluent builder of [[AuthError]]
 * @hidden
 */
export class AuthErrorBuilder {
  message = 'Unknown error'
  severity: AuthErrorSeverity = AuthErrorSeverity.Warn
  category: AuthErrorCategory = AuthErrorCategory.Unknown
  platform: AuthErrorPlatform = AuthErrorPlatform.Unknown
  domain: AuthErrorDomain = AuthErrorDomain.Unknown
  numeral: AuthErrorNumeral = AuthErrorNumeral.Unspecified
  metadata?: AuthErrorMetadata

  constructor() {
    this.platform = AuthErrorPlatform.Web
  }

  withMessage(message: string): this {
    this.message = message
    return this
  }

  withSeverity(severity: AuthErrorSeverity): this {
    this.severity = severity
    return this
  }

  withCategory(category: AuthErrorCategory): this {
    this.category = category
    return this
  }

  withPlatform(platform: AuthErrorPlatform): this {
    this.platform = platform
    return this
  }

  withDomain(domain: AuthErrorDomain): this {
    this.domain = domain
    return this
  }

  withNumeral(numeral: AuthErrorNumeral): this {
    this.numeral = numeral
    return this
  }

  withMetadata(metadata: { [key: string]: any }): this {
    this.metadata = this.metadata || {}
    Object.assign(this.metadata, metadata)
    return this
  }

  private _withMetadataProperty(propertyName: AuthErrorMetadataProperty, value: any): this {
    if (value != null) {
      this.metadata = this.metadata || {}
      this.metadata[propertyName] = value
    } else if (this.metadata && propertyName in this.metadata) {
      delete this.metadata[propertyName]
    }
    return this
  }

  withRecoverySuggestion(recoverySuggestion: string): this {
    return this._withMetadataProperty(AuthErrorMetadataProperty.RecoverySuggestion, recoverySuggestion)
  }

  withSubErrorCode(subErrorCode: string): this {
    return this._withMetadataProperty(AuthErrorMetadataProperty.SubErrorCode, subErrorCode)
  }

  withSubErrorMessage(subErrorMessage: string): this {
    return this._withMetadataProperty(AuthErrorMetadataProperty.SubErrorMessage, subErrorMessage)
  }

  getErrorCode(): AuthErrorCode {
    const errorCodeBuilder = new AuthErrorCodeBuilder(this.category, this.platform, this.domain, this.numeral)
    const errorCode = errorCodeBuilder.build()
    return errorCode
  }

  build(): AuthError {
    const { severity, message, metadata } = this
    const code = this.getErrorCode()
    const error: AuthError = {
      message,
      code,
      severity,
    }
    if (metadata) {
      error.metadata = metadata
    }
    return error
  }
}
