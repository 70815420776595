/**
 * ClientlessAuthEngine
 */
import { ClientlessAPI } from './ClientlessAPI'
import { ClientlessAdobeConfig, ClientlessAdobeConfigMvpd, MetadataKey, } from './ClientlessAuthTypes'
import {
  AuthErrorBuilder,
  AuthErrorCategory,
  AuthErrorDomain,
  AuthErrorNumeral,
  AuthErrorSeverity,
  isAuthError,
} from './AuthError'
import { DefaultDeviceInfo, DeviceInfo } from './DeviceInfo'
import { ImageTemplateBuilderData, MVPDConfigAPI, MVPDConfiguration, Provider } from './MVPDConfigAPI'
import { AuthConfiguration } from './AuthConfiguration'
import { IAuthEngine, PresentRegCodeResult } from './IAuthEngine'
import {
  AuthEngineResponseType,
  AuthorizationDataResponse,
  AuthStatusResponse,
  GetSelectedProviderResponse,
  MetadataDataResponse,
  PreauthorizationDataResponse,
  SetRequestorResponse,
} from './ClientlessAuthEngineResponse'
import { Deferred } from './Deferred'
import { Timer, TimerMode } from './Timer'
import { uuid } from './uuid'

type Writeable<T> = { -readonly [P in keyof T]: T[P] }

interface PollerResult {
  promise: Promise<boolean>
  cancel: () => void
}

const SECOND = 1000
const MINUTE = 60 * SECOND

const STORE_KEY_PREFIX = 'com.turner.top-lite'
const DEVICE_ID_STORE_KEY = `${STORE_KEY_PREFIX}.deviceId`

export class ClientlessAuthEngine implements IAuthEngine {
  cachedUserGuid?: string
  private _ready: Deferred<boolean> = new Deferred()
  ready: Promise<boolean> = this._ready.promise
  // Assigned in prepare -> setRequestor
  private _configService!: MVPDConfigAPI
  private _clientlessApi?: ClientlessAPI
  private _mvpdConfig?: MVPDConfiguration
  private _deviceInfo?: DeviceInfo
  private _pollingInterval: number = 30 * SECOND
  private _poller?: PollerResult

  constructor(public authConfig: AuthConfiguration) {}

  async prepare(): Promise<AuthStatusResponse> {
    const requestorResponse = await this._setRequestor()
    if (!requestorResponse.status) {
      const error = new AuthErrorBuilder()
        .withMessage('Failed to set requestor during prepare()')
        .withCategory(AuthErrorCategory.Initialization)
        .withDomain(AuthErrorDomain.Nexus)
        .withNumeral(AuthErrorNumeral.AdobeSetRequestorFailure)
        .withRecoverySuggestion(
          'Verify the brand/environment/softwareStatement provided in the AuthConfiguration and/or URL Scheme in Info.plist'
        )
        .build()
      return Promise.reject(error)
    }
    return await this.checkAuthenticatedStatus()
  }

  buildImageURL(data: ImageTemplateBuilderData): string {
    return this._configService.buildImageURLString(data)
  }

  async checkAuthenticatedStatus(): Promise<AuthStatusResponse> {
    await this.ready
    if (!this._clientlessApi) return buildAuthEngineError()
    let result: Writeable<AuthStatusResponse> = {
      type: AuthEngineResponseType.Did_Set_Authentication_Status,
      isAuthenticated: false,
      info: '',
    }
    try {
      const data = await this._clientlessApi.requestAuthNToken()

      // will logout if you're logged in with a provider not in the MVPD Config
      try {
        const providerData = await this.getSelectedProvider()
        result.provider = providerData.provider
        result.adobeHashId = data.userId
        this.cachedUserGuid = data.userId
      } catch (noProvider) {
        this.cachedUserGuid = undefined
        this.cancelAuthentication()
        result = await this.logout()
        result.info = AuthErrorNumeral.MvpdConfigProviderNotFound
        return result
      }
      result.isAuthenticated = true
    } catch (error) {
      if (isAuthError(error)) {
        const authError = error
        result.isAuthenticated = false
        result.info = `[${authError.code}] ${authError.message}`
      }
    }
    return result
  }

  async getSelectedProvider(): Promise<GetSelectedProviderResponse> {
    await this.ready
    if (!this._clientlessApi) return buildAuthEngineError()
    const mvpd = this._clientlessApi.getMvpdID()
    let provider = null
    if (mvpd) {
      provider = this._getMvpdConfigEntry(mvpd) || null
    }
    if (!provider) return Promise.reject(null)
    // FIXME: check if `null` is a valid response, or should we reject?
    const result: GetSelectedProviderResponse = {
      type: AuthEngineResponseType.Did_Receive_Selected_Provider,
      provider,
    }
    return result
  }

  async login(presentRegCodeCallback: (data: PresentRegCodeResult) => void): Promise<AuthStatusResponse> {
    await this.ready
    if (!this._clientlessApi) {
      return buildAuthEngineError()
    }
    try {
      await this.logout()
    } catch {
      // intentionally ignore logout failure
    }

    const regcodeResponse = await this._clientlessApi.requestRegCode()
    // Emit event for regcode
    presentRegCodeCallback({
      registrationCode: regcodeResponse.code,
      registrationURL: this.authConfig.registrationURL ?? '',
      helpURL: this.authConfig.helpURL ?? '',
    })

    if (this._poller) this._poller.cancel()
    const poller: PollerResult = this._pollForAuthNSuccess()
    this._poller = poller
    const { promise } = poller
    await promise
    return await this.checkAuthenticatedStatus()
  }

  cancelAuthentication(): void {
    if (this._poller) {
      this._poller.cancel()
    }
  }

  async authorize(channel: string): Promise<AuthorizationDataResponse> {
    await this.ready
    if (!this._clientlessApi) return buildAuthEngineError()
    await this._clientlessApi.authorize(channel)
    const shortMediaToken = await this._clientlessApi.requestShortMediaToken(channel)
    const { serializedToken } = shortMediaToken
    let decodedToken
    try {
      decodedToken = window.atob(serializedToken)
    } catch {
      // fallback to `serializedToken` as `decodedToken` will be undefined
    }
    const result: AuthorizationDataResponse = {
      type: AuthEngineResponseType.Did_Set_Token,
      channel,
      token: decodedToken || serializedToken,
    }
    return result
  }

  // This preauthorize is meant to be used from the client app, not from the second screen regcode app. The REST API
  // call doesn't accept caching options like the AccessEnabler does.
  async preauthorize(channels: string[]): Promise<PreauthorizationDataResponse> {
    await this.ready
    if (!this._clientlessApi) return buildAuthEngineError()

    const response = await this._clientlessApi.requestPreauthorizedResources(channels)
    const result: PreauthorizationDataResponse = {
      type: AuthEngineResponseType.Did_Receive_Preauthorized_Resources,
      decisions: response.resources,
    }

    return result
  }

  async logout(): Promise<AuthStatusResponse> {
    await this.ready
    if (!this._clientlessApi) return buildAuthEngineError()
    try {
      await this._clientlessApi.logout()
    } finally {
      // ensure these are cleared
      this.cachedUserGuid = undefined
    }

    // successful logout() response
    const result: AuthStatusResponse = {
      type: AuthEngineResponseType.Did_Set_Authentication_Status,
      isAuthenticated: false,
      info: '',
    }
    return result
  }

  async fetchMetadata(key: MetadataKey, args?: Record<string, string>): Promise<MetadataDataResponse> {
    if (!key) return buildFetchMetadataTypeError()
    // Depending upon the key, you have to fetch from different sources.
    switch (key) {
      case MetadataKey.DEVICE_ID:
        return this._getDeviceIdMetadata()
      case MetadataKey.AUTHENTICATED_TTL:
        return this._fetchAuthNTTLMetadata()
      case MetadataKey.AUTHORIZED_TTL: {
        let requestedResource = ''
        if (typeof args === 'object' && args !== null && !Array.isArray(args)) {
          const { channel, resource, resourceId, resourceID } = args
          requestedResource = channel || resource || resourceId || resourceID || ''
        }
        return this._fetchAuthZTTLMetadata(requestedResource)
      }
      default:
        // ASSUME: only authZ TTL uses `args`
        return this._fetchUserMetadata(key)
    }
  }

  private async _setRequestor(): Promise<SetRequestorResponse> {
    const {
      environment,
      brand,
      softwareStatement,
      redirectUri,
      mvpdConfigURL,
      serviceAppId,
      platform,
      deviceInfo,
      pollingInterval,
      ecid,
    } = this.authConfig
    // Generating UUID for deviceId
    const deviceId = await getDeviceID()
    const requestorId = brand

    // Disabling SSO by using a unique appId for requestorId
    const appId = `${requestorId}:top-lite-auth`

    const MIN_POLLING_INTERVAL = 10 * SECOND
    const MAX_POLLING_INTERVAL = 2 * MINUTE

    this._pollingInterval =
      pollingInterval && isFinite(pollingInterval)
        ? Math.min(Math.max(pollingInterval * SECOND, MIN_POLLING_INTERVAL), MAX_POLLING_INTERVAL)
        : this._pollingInterval

    const baseDeviceInfo: DeviceInfo = {
      model: 'JS',
      osName: 'JS',
      application: appId,
    }
    const defaultDeviceInfo = DefaultDeviceInfo.get()
    this._deviceInfo = Object.assign({}, baseDeviceInfo, defaultDeviceInfo, deviceInfo)

    this._clientlessApi = new ClientlessAPI({
      adobeEnv: environment,
      deviceId,
      deviceInfo: this._deviceInfo,
      deviceType: platform,
      ecid,
      softwareStatement,
      redirectUri,
      requestorId,
    })
    this._configService = new MVPDConfigAPI(mvpdConfigURL, serviceAppId, platform)
    await Promise.all([
      this._clientlessApi.requestClientRegistration(),
      this._configService.fetchConfig()
    ])
    await this._clientlessApi.requestClientAccessToken()
    this._mvpdConfig = this._configService.config

    // Now let's fetch Adobe's config and merge with ours.
    const adobeConfig: ClientlessAdobeConfig = await this._clientlessApi.requestConfig()
    this._mvpdConfig.mvpd = mergeMVPDLists(this._mvpdConfig.mvpd, adobeConfig.mvpd)
    this._ready.resolve(true)

    const response: SetRequestorResponse = {
      type: AuthEngineResponseType.Did_Set_Requestor_Complete,
      status: true,
    }

    return response
  }

  private _getMvpdConfigEntry(mvpdId: string): Provider | undefined {
    if (!this._mvpdConfig || !mvpdId) {
      return
    }

    return this._mvpdConfig.mvpd.find((mvpd) => mvpd.id === mvpdId)
  }

  private _getDeviceIdMetadata(): MetadataDataResponse {
    const deviceId = this._clientlessApi?.getDeviceID() || null
    const result: MetadataDataResponse = {
      type: AuthEngineResponseType.Did_Set_Metadata_Status,
      key: MetadataKey.DEVICE_ID,
      encrypted: false,
      value: deviceId,
    }
    return result
  }

  private async _fetchAuthNTTLMetadata(): Promise<MetadataDataResponse> {
    if (!this._clientlessApi) return buildAuthEngineError()
    const value: number | null = this._clientlessApi.getAuthNTTL()
    const result: MetadataDataResponse = {
      type: AuthEngineResponseType.Did_Set_Metadata_Status,
      key: MetadataKey.AUTHENTICATED_TTL,
      encrypted: false,
      value,
    }
    return result
  }

  private async _fetchAuthZTTLMetadata(resource = ''): Promise<MetadataDataResponse> {
    if (!this._clientlessApi) return buildAuthEngineError()
    const value: number | null = this._clientlessApi.getAuthZTTL(resource)
    const result: MetadataDataResponse = {
      type: AuthEngineResponseType.Did_Set_Metadata_Status,
      key: MetadataKey.AUTHORIZED_TTL,
      encrypted: false,
      resource,
      value,
    }
    return result
  }

  private async _fetchUserMetadata(key: MetadataKey): Promise<MetadataDataResponse> {
    if (!this._clientlessApi) return buildAuthEngineError()
    const value = await this._clientlessApi.requestUserMetadataValue(key)
    const result: MetadataDataResponse = {
      type: AuthEngineResponseType.Did_Set_Metadata_Status,
      key,
      encrypted: false,
      value,
    }
    return result
  }

  private _pollForAuthNSuccess(): PollerResult {
    if (!this._clientlessApi) throw new TypeError('Clientless API is not available')
    const deferred = new Deferred<boolean>()
    const TIMEOUT_MS = 15 * MINUTE
    const pollingTimer = Timer(
      TimerMode.Interval,
      async () => {
        if (!this._clientlessApi) {
          stop()
          return
        }

        try {
          const success = await this._clientlessApi.checkAuthNToken()
          deferred.resolve(success)
          stop()
        } catch (error) {
          if (isAuthError(error)) {
            const authError = error
            // Ignore AuthError if it's just telling us you're not authN'ed.
            // We'll continue polling, unless the error is ServiceException.
            // Or until 15 minutes have passed.
            // Or until someone else calls `cancel()`.
            // (We're returning so it can be used by `cancelAuthentication()`.)
            // FIXME: authError.code === "AN01AE0631" -- not an AuthErrorNumeral!
            // Trying to use `.endsWith()`, but that won't exist on old browsers without polyfill.
            if (authError.code.endsWith(AuthErrorNumeral.ClientlessCheckAuthNServiceException)) {
              deferred.reject(authError)
              stop()
            }
          }
        }
      },
      this._pollingInterval
    )
    const stop = () => {
      pollingTimer.stop()
      timeoutTimer.stop()
    }
    const cancel = (reason: AuthErrorNumeral) => {
      return () => {
        stop()
        deferred.reject(
          new AuthErrorBuilder()
            .withMessage('Canceled authentication')
            .withCategory(AuthErrorCategory.Authentication)
            .withDomain(AuthErrorDomain.Shared)
            .withNumeral(reason)
            .build()
        )
      }
    }
    const timeoutTimer = Timer(TimerMode.Timeout, cancel(AuthErrorNumeral.AuthenticationTimeout), TIMEOUT_MS)
    pollingTimer.start()
    timeoutTimer.start()
    const result: PollerResult = {
      promise: deferred.promise,
      cancel: cancel(AuthErrorNumeral.ClientlessCheckAuthNCanceled),
    }
    return result
  }
}

let sessionDeviceId = ''
const getDeviceID = () => {
  if (sessionDeviceId) {
    return sessionDeviceId
  }

  let deviceId = ''
  deviceId = window.localStorage.getItem(DEVICE_ID_STORE_KEY) || ''

  if (!deviceId) {
    deviceId = uuid()
    window.localStorage.setItem(DEVICE_ID_STORE_KEY, deviceId)
  }

  sessionDeviceId = deviceId
  return deviceId
}

const buildAuthEngineError = () =>
  Promise.reject(
    new AuthErrorBuilder()
      .withMessage('Auth Engine is missing or was disposed')
      .withSeverity(AuthErrorSeverity.Fatal)
      .withCategory(AuthErrorCategory.Initialization)
      .withDomain(AuthErrorDomain.Target)
      .withNumeral(AuthErrorNumeral.ClientlessAPIUninitialized)
      .withRecoverySuggestion('setRequestor() must be called first')
      .build()
  )

const buildFetchMetadataTypeError = () =>
  Promise.reject(
    new AuthErrorBuilder()
      .withMessage('TypeError: A valid key param is required for "fetchMetadata"')
      .withCategory(AuthErrorCategory.Metadata)
      .withDomain(AuthErrorDomain.Runtime)
      .withNumeral(AuthErrorNumeral.TypeError)
      .build()
  )

/**
 * Merge Adobe's MVPD list with our MVPDConfig allowlist.
 *
 * Considerations:
 *   - our MVPDConfig could include a provider that Adobe no longer supports
 *   - Adobe's config could include a provider that we have not yet onboarded
 *   - Adobe's config could list a provider that our allowlist disables for a platform
 *
 * @param mvpdConfigMVPDs our MVPDConfig list of providers, considered the source
 * @param adobeConfigMVPDs Adobe's MVPD list, used to allow/disallow what's in our MVPDConfig
 *
 * @see `WebAuthEngine`'s `mergeMVPDLists`
 * *Copied from WebAuthEngine.ts, but using Clientless type*
 *
 * @hidden
 */
const mergeMVPDLists = (mvpdConfigMVPDs: Provider[], adobeConfigMVPDs: ClientlessAdobeConfigMvpd[]): Provider[] => {
  if (!adobeConfigMVPDs?.length) return mvpdConfigMVPDs

  // A look-up table of MVPD IDs in Adobe's config
  const adobeConfigMvpdMap = adobeConfigMVPDs.reduce((lookup, mvpd) => {
    lookup[mvpd.id] = mvpd
    return lookup
  }, {} as Record<string, ClientlessAdobeConfigMvpd>)

  // Looping through our MVPD Config, merging with Adobe's provider data, if available
  const newProviders = mvpdConfigMVPDs
    .map((mvpd: Provider) => {
      const { id } = mvpd
      // It's possible a provider in our config does not exist in Adobe's config
      if (id in adobeConfigMvpdMap) {
        const adobeData = adobeConfigMvpdMap[id]
        // Overwrite Adobe's config entry with our config entry -- just need Adobe's extra props
        const result = Object.assign({}, adobeData, mvpd)
        delete result.logoUrl
        return result
      }
      return undefined
    })
    // Filter out the `undefined`s from skipping any IDs not in MVPD Config
    .filter(Boolean) as Provider[]

  return newProviders
}
