/**
 * ClientlessAPI
 */
import parseUrl from 'url-parse'
import MD5 from 'crypto-js/md5'

import {
  ClientlessAdobeConfig,
  ClientlessAuthNToken,
  ClientlessAuthZToken,
  ClientlessDeviceIdParams,
  ClientlessNetworkCallOptions,
  ClientlessPreauthorizedResources,
  ClientlessRegCodeRequestParams,
  ClientlessRegCodeResponse,
  ClientlessShortMediaToken,
  ClientlessUserMetadata,
  isClientlessAuthNToken,
  isClientlessAuthZToken,
  isClientlessUserMetadata,
  MetadataKey,
  MetadataValue,
  ResourceID,
} from './ClientlessAuthTypes'

import {
  createAuthNTokenNetworkError,
  createAuthNTokenParseError,
  createAuthorizeResponseParseError,
  createAuthZTokenNetworkError,
  createAuthZTokenParseError,
  createCheckAuthNTokenNetworkError,
  createClientAccessTokenBodyError,
  createClientAccessTokenNetworkError,
  createClientRegistrationBodyError,
  createClientRegistrationInitError,
  createClientRegistrationNetworkError,
  createConfigBodyError,
  createConfigNetworkError,
  createConfigParseError,
  createLogoutNetworkError,
  createMissingRequestorIdError,
  createMissingSoftwareStatementError,
  createPreauthorizeInvalidInputError,
  createPreauthorizeNetworkError,
  createPreauthorizeResponseParseError,
  createRegCodeNetworkError,
  createRegCodeParseError,
  createShortMediaTokenNetworkError,
  createShortMediaTokenParseError,
  createUserMetadataNetworkError,
  createUserMetadataParseError,
  HTTPError,
  requestAuthorizeNetworkError,
} from './ClientlessAuthErrors'
import { DeviceInfo } from './DeviceInfo'

const MIN_REGCODE_TTL = 600
const DEFAULT_REGCODE_TTL = 1800
const MAX_REGCODE_TTL = 36000

const ADOBE_STAGING_ENV = 'staging'
const ADOBE_PROD_ORIGIN = 'https://api.auth.adobe.com'
const ADOBE_STAGING_ORIGIN = 'https://api.auth-staging.adobe.com'
const GET = 'GET'
const POST = 'POST'
const DELETE = 'DELETE'
const defaultHeaders = {
  Accept: 'application/json',
}
const jsonHeaders = {
  'Content-Type': 'application/json'
}
const urlencodedHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
}

const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE

/** @hidden */
export type ClientlessAPIConstructorParam = {
    adobeEnv: 'staging' | 'production';
    deviceId: string;
    deviceInfo?: DeviceInfo;
    deviceType: string;
    ecid?: string;
    hashDeviceId?: boolean;
    redirectUri?: string;
    regcodeTtl?: number;
    registrationUrl?: string;
    requestorId: string;
    softwareStatement: string;
};

const appendQuery = (url: string, params: Record<string, string>): string => {
  const parsed = parseUrl(url, true)
  parsed.set('query', {
    ...parsed.query,
    ...params,
  })
  return parsed.toString()
}

export class ClientlessAPI {
  private _adobeOrigin: string
  private _deviceId: string
  private _deviceInfo?: DeviceInfo
  private _deviceType: string
  private _ecid?: string
  private _softwareStatement: string
  private _redirectUri?: string
  private _accessToken?: string
  private _accessTokenExpiry?: number
  private _requestorId: string
  private _registrationUrl?: string
  private _regcodeTtl: number

  private _hashedDeviceId: string
  private _hashedUserId: string | null = null
  private _cachedMvpdId: string | null = null
  private _userMetadata?: ClientlessUserMetadata
  private _userMetadataUpdated?: number
  private _authNToken?: ClientlessAuthNToken
  private _authZTokens: Map<ResourceID, ClientlessAuthZToken> = new Map()

  private _clientId?: string
  private _clientSecret?: string
  private _grantType?: string

  constructor(opts: ClientlessAPIConstructorParam) {
    const {
      adobeEnv,
      deviceId = '',
      deviceInfo,
      deviceType = '',
      ecid,
      hashDeviceId = true,
      redirectUri,
      regcodeTtl = DEFAULT_REGCODE_TTL,
      registrationUrl,
      requestorId,
      softwareStatement,
    }: ClientlessAPIConstructorParam = opts

    if (!requestorId) {
      throw createMissingRequestorIdError()
    }

    if (!softwareStatement) {
      throw createMissingSoftwareStatementError()
    }

    this._adobeOrigin = adobeEnv === ADOBE_STAGING_ENV ? ADOBE_STAGING_ORIGIN : ADOBE_PROD_ORIGIN

    this._deviceId = deviceId
    this._deviceInfo = deviceInfo
    this._deviceType = deviceType
    this._ecid = ecid
    this._softwareStatement = softwareStatement
    this._redirectUri = redirectUri
    this._requestorId = requestorId
    this._registrationUrl = registrationUrl
    this._hashedDeviceId = this._deviceId && hashDeviceId ? MD5(this._deviceId).toString() : this._deviceId
    this._regcodeTtl = isFinite(regcodeTtl)
      ? Math.min(Math.max(regcodeTtl, MIN_REGCODE_TTL), MAX_REGCODE_TTL)
      : DEFAULT_REGCODE_TTL
  }

  async requestClientRegistration(): Promise<void> {
    const path = '/o/client/register'
    const reqData: Record<string, string> = {
      software_statement: this._softwareStatement,
    }
    if (this._redirectUri) {
      reqData['redirect_uri'] = this._redirectUri
    }

    let res: Response | undefined
    let networkFailure
    let parseFailure
    let responseData

    const headers = {
      ...defaultHeaders,
      ...jsonHeaders,
      ...this._getDeviceInfoHeaders(),
    }

    try {
      res = await this._call({
        method: POST,
        path,
        headers,
        body: JSON.stringify(reqData),
      })
    } catch (networkError) {
      networkFailure = networkError
    }

    if (res) {
      try {
        responseData = await res.json()
      } catch (bodyError) {
        parseFailure = bodyError
      }
    }

    if (networkFailure) {
      return Promise.reject(createClientRegistrationNetworkError(networkFailure, responseData ?? {}))
    }

    if (parseFailure) {
      return Promise.reject(createClientRegistrationBodyError(parseFailure))
    }

    this._clientId = responseData.client_id
    this._clientSecret = responseData.client_secret
    this._grantType = Array.isArray(responseData.grant_types) && responseData.grant_types.length > 0 ? responseData.grant_types[0] : 'client_credentials'
  }

  private async _requestClientAccessToken(): Promise<void> {
    let res: Response

    if (!this._clientId || !this._clientSecret) {
      return Promise.reject(createClientRegistrationInitError())
    }

    const reqData: Record<string, string> = {
      client_id: this._clientId,
      client_secret: this._clientSecret,
    }
    if (this._grantType) {
      reqData['grant_type'] = this._grantType
    }
    const searchParams = new URLSearchParams(reqData)
    const headers = {
      ...defaultHeaders,
      ...urlencodedHeaders,
      ...this._getDeviceInfoHeaders()
    }

    try {
      res = await this._call({
        method: POST,
        path: '/o/client/token',
        headers,
        body: searchParams.toString(),
      })
    } catch (networkError) {
      return Promise.reject(createClientAccessTokenNetworkError(networkError))
    }

    try {
      const { access_token, expires_in, created_at } = await res.json()
      this._accessToken = access_token
      this._accessTokenExpiry = created_at + (expires_in * 1000)
    } catch (bodyError) {
      return Promise.reject(createClientAccessTokenBodyError(bodyError))
    }
  }

  private _clientTokenRequest?: Promise<void>

  // a sort of debounced facade, in case concurrent calls are run
  async requestClientAccessToken(): Promise<void> {
    if (this._clientTokenRequest) {
      return this._clientTokenRequest
    }

    this._clientTokenRequest = this._requestClientAccessToken()

    this._clientTokenRequest.finally(() => {
      this._clientTokenRequest = undefined
    })
    return this._clientTokenRequest
  }

  /**
   * ## Provide MVPD List
   */
  async requestConfig(): Promise<ClientlessAdobeConfig> {
    let res: Response

    try {
      res = await this._call({
        method: GET,
        path: `/api/v1/config/${encodeURI(this._requestorId)}`,
        headers: defaultHeaders,
      })
    } catch (networkError) {
      return Promise.reject(createConfigNetworkError(networkError))
    }

    let data

    try {
      data = await res.json()
    } catch (bodyError) {
      return Promise.reject(createConfigBodyError(res, bodyError))
    }

    try {
      const config = adobeConfigToMvpdList(data)
      if (!config) throw new Error('Missing mvpd data in Adobe config')
      return config
    } catch (parseError) {
      return Promise.reject(createConfigParseError(parseError))
    }
  }

  /**
   * ## Create Registration Code / Login URI
   */
  async requestRegCode(): Promise<ClientlessRegCodeResponse> {
    this._clearCachedData()

    const reqData: ClientlessRegCodeRequestParams = this._getDeviceIdParams()

    if (this._registrationUrl) reqData.registrationURL = this._registrationUrl
    if (this._regcodeTtl) reqData.ttl = `${this._regcodeTtl}`

    const headers = {
      ...defaultHeaders,
      ...urlencodedHeaders,
      ...this._getDeviceInfoHeaders()
    }

    let res: Response
    try {
      res = await this._call({
        method: POST,
        path: `/reggie/v1/${encodeURI(this._requestorId)}/regcode`,
        headers,
        body: new URLSearchParams(reqData).toString(),
      })
    } catch (networkError) {
      return Promise.reject(createRegCodeNetworkError(networkError))
    }

    let data: ClientlessRegCodeResponse

    try {
      data = await res.json()
    } catch (parseError) {
      return Promise.reject(createRegCodeParseError(parseError))
    }

    return data
  }

  /**
   * ## Return Registration Record
   *
   * Returns registration code record containing registration code UUID, registration code, and hashed device ID.
   *
   * NOTE: This is likely intended to be used on 2nd screen
   * in order to confirm that they received a valid reg code.
   */
  async validateRegCode(regCode = ''): Promise<ClientlessRegCodeResponse> {
    let res: Response

    try {
      res = await this._call({
        method: GET,
        path: `/reggie/v1/${encodeURI(this._requestorId)}/regcode/${encodeURI(regCode)}`,
        headers: defaultHeaders
      })
    } catch (networkError) {
      return Promise.reject(createRegCodeNetworkError(networkError))
    }

    let data: ClientlessRegCodeResponse

    try {
      data = await res.json()
    } catch (parseError) {
      return Promise.reject(createRegCodeParseError(parseError))
    }

    return data
  }

  /**
   * ## Delete Registration Record
   *
   * Deletes the reg code record and releases the reg code for reuse.
   */
  async releaseRegCode(regCode = ''): Promise<boolean> {
    try {
      await this._call({
        method: DELETE,
        path: `/reggie/v1/${encodeURI(this._requestorId)}/regcode/${encodeURI(regCode)}`,
        headers: defaultHeaders,
      })
      return true
    } catch (networkError) {
      return Promise.reject(createRegCodeNetworkError(networkError))
    }
  }

  /**
   * ## Check Authentication Token
   *
   * If successful, the Promise resolves.
   *
   * @remarks This call doesn't return any MVPD info.
   * This method is only to be called when polling for login success.
   * Users should call `requestAuthNToken` to update cached MVPD ID.
   */
  async checkAuthNToken(): Promise<boolean> {
    const searchParams = {
      requestor: this._requestorId,
      ...this._getDeviceIdParams(),
    }
    const headers = {
      ...defaultHeaders,
      ...this._getDeviceInfoHeaders()
    }

    try {
      await this._call({
        method: GET,
        path: '/api/v1/checkauthn',
        headers,
        searchParams,
      })
      return true
    } catch (networkError) {
      this._clearCachedData()
      return Promise.reject(createCheckAuthNTokenNetworkError(networkError))
    }
  }

  /**
   * ## Retrieve Authentication Token
   */
  async requestAuthNToken(): Promise<ClientlessAuthNToken> {
    const searchParams = {
      requestor: this._requestorId,
      ...this._getDeviceIdParams(),
    }
    const headers = {
      ...defaultHeaders,
      ...this._getDeviceInfoHeaders()
    }

    let res: Response

    try {
      res = await this._call({
        method: GET,
        path: '/api/v1/tokens/authn',
        headers,
        searchParams,
      })
    } catch (networkError) {
      return Promise.reject(createAuthNTokenNetworkError(networkError))
    }

    let data: ClientlessAuthNToken
    try {
      data = await res.json()
      if (!isClientlessAuthNToken(data)) {
        throw new Error('missing required fields for authn token')
      }
      // convert to a number from adobe string
      data.expires = ensureExpiresValue(data.expires.toString())
    } catch (parseError) {
      return Promise.reject(createAuthNTokenParseError(parseError))
    }

    this._storeAuthNToken(data)
    return data
  }

  /**
   * ## Initiate Authorization
   */
  async authorize(resource = ''): Promise<ClientlessAuthZToken> {
    const searchParams = {
      requestor: this._requestorId,
      resource,
      ...this._getDeviceIdParams(),
    }
    const headers = {
      ...defaultHeaders,
      ...this._getDeviceInfoHeaders()
    }

    let res: Response

    try {
      res = await this._call({
        method: GET,
        path: '/api/v1/authorize',
        headers,
        searchParams,
      })
    } catch (networkError) {
      const error = await requestAuthorizeNetworkError(networkError)
      return Promise.reject(error)
    }

    let data: ClientlessAuthZToken

    try {
      data = await res.json()
      if (!isClientlessAuthZToken(data)) {
        throw new Error('missing required fields for authn token')
      }
      // convert to a number from adobe string
      data.expires = ensureExpiresValue(data.expires.toString())
    } catch (parseError) {
      return Promise.reject(createAuthorizeResponseParseError(parseError))
    }

    this._storeAuthZToken(resource, data)

    return data
  }

  /**
   * ## Retrieve Authorization Token
   *
   * @example Success: AuthZTokenResponse
   * ```json
   * {
   *   "mvpd": "sampleMvpdId",
   *   "resource": "sampleResourceId",
   *   "requestor": "sampleRequestorId",
   *   "expires": "1348148289000",
   *   "proxyMvpd": "sampleProxyMvpdId"
   * }
   * ```
   *
   * @example Unsuccessful authZ response: AuthZTokenErrorResponse
   * ```json
   * {
   *   "status": 404,
   *   "message": "Not Found",
   *   "details": null
   * }
   * ```
   */
  async requestAuthZToken(resource = ''): Promise<ClientlessAuthZToken> {
    const searchParams = {
      requestor: this._requestorId,
      resource,
      ...this._getDeviceIdParams(),
    }
    const headers = {
      ...defaultHeaders,
      ...this._getDeviceInfoHeaders()
    }

    let res: Response

    try {
      res = await this._call({
        method: GET,
        path: '/api/v1/tokens/authz',
        headers,
        searchParams,
      })
    } catch (networkError) {
      return Promise.reject(createAuthZTokenNetworkError(networkError))
    }

    let data

    try {
      data = await res.json()
      if (!isClientlessAuthZToken(data)) {
        throw new Error('missing required fields for authn token')
      }
      // convert to a number from adobe string
      data.expires = ensureExpiresValue(data.expires.toString())
    } catch (parseError) {
      return Promise.reject(createAuthZTokenParseError(parseError))
    }

    this._storeAuthZToken(resource, data)

    return data as ClientlessAuthZToken
  }

  // TODO: test this API call with MRSS

  /**
   * ## Obtain Short Media Token
   */
  async requestShortMediaToken(resource = ''): Promise<ClientlessShortMediaToken> {
    const searchParams = {
      requestor: this._requestorId,
      resource,
      ...this._getDeviceIdParams(),
    }
    const headers = {
      ...defaultHeaders,
      ...this._getDeviceInfoHeaders()
    }

    let res: Response

    try {
      res = await this._call({
        method: GET,
        path: '/api/v1/tokens/media',
        headers,
        searchParams,
      })
    } catch (networkError) {
      return Promise.reject(createShortMediaTokenNetworkError(networkError))
    }

    let data

    try {
      data = await res.json()
    } catch (parseError) {
      return Promise.reject(createShortMediaTokenParseError(parseError))
    }

    const { expires } = data
    data.expires = ensureExpiresValue(expires)

    return data as ClientlessShortMediaToken
  }

  /**
   * ## Retrieve List of Preauthorized Resources
   *
   * @example Sample response:
   * ```json
   * {
   *   "resources": [
   *     {
   *       "id": "TNT",
   *       "authorized": true
   *     },
   *     {
   *       "id": "XYZ",
   *       "authorized": false
   *     }
   *   ]
   * }
   * ```
   */
  async requestPreauthorizedResources(resources: string[]): Promise<ClientlessPreauthorizedResources> {
    if (!resources || !Array.isArray(resources) || !resources.length) {
      return Promise.reject(createPreauthorizeInvalidInputError())
    }

    // TODO: sort resources? what other things did we do in web auth?
    const resource = resources.join(',')
    // Keep URL length under 256 bytes: if there's a large list, may use POST
    const method = resource.length < 100 ? GET : POST

    const reqData = {
      requestor: this._requestorId,
      resource,
      ...this._getDeviceIdParams(),
    }
    const headers = {
      ...defaultHeaders,
      ...this._getDeviceInfoHeaders()
    }

    let res: Response
    const opts: ClientlessNetworkCallOptions = {
      method,
      path: '/api/v1/preauthorize/',
      headers,
    }
    if (method === POST) {
      opts.body = new URLSearchParams(reqData)
    } else {
      opts.searchParams = reqData
    }

    try {
      res = await this._call(opts)
    } catch (networkError) {
      return Promise.reject(createPreauthorizeNetworkError(networkError))
    }

    let data: ClientlessPreauthorizedResources

    try {
      data = await res.json()
    } catch (parseError) {
      return Promise.reject(createPreauthorizeResponseParseError(parseError))
    }

    return data
  }

  /**
   * ## Initiate Logout
   *
   * The logout call currently has the following limitation:
   * it clears the AuthN and AuthZ tokens from storage, but
   * does *not* call the MVPD logout endpoint.
   *
   * NOTE: If you're already logged out, `logout()` is successful!
   */
  async logout(): Promise<Response> {
    this._clearCachedData()
    const headers = {
      ...defaultHeaders,
      ...this._getDeviceInfoHeaders()
    }
    try {
      const res = this._call({
        method: DELETE,
        path: '/api/v1/logout',
        headers,
        searchParams: this._getDeviceIdParams(),
      })
      return res
    } catch (networkError) {
      return Promise.reject(createLogoutNetworkError(networkError))
    }
  }

  /**
     * ## User Metadata
     */
  async requestUserMetadata(): Promise<ClientlessUserMetadata> {
    const searchParams = {
      requestor: this._requestorId,
      ...this._getDeviceIdParams(),
    }
    const headers = {
      ...defaultHeaders,
      ...this._getDeviceInfoHeaders()
    }

    let res: Response

    try {
      res = await this._call({
        method: GET,
        path: '/api/v1/tokens/usermetadata',
        headers,
        searchParams,
      })
    } catch (networkError) {
      return Promise.reject(createUserMetadataNetworkError(networkError))
    }

    let data: ClientlessUserMetadata
    try {
      data = await res.json()
      if (!isClientlessUserMetadata(data)) {
        throw new Error('missing required fields for user metadata')
      }
    } catch (parseError) {
      return Promise.reject(createUserMetadataParseError(parseError))
    }

    return data
  }

  getDeviceID(): string | null {
    return this._hashedDeviceId || null
  }

  getUserID(): string | null {
    return this._hashedUserId || null
  }

  getMvpdID(): string | null {
    return this._cachedMvpdId || null
  }

  getAuthNTTL(): number | null {
    const authNToken = this._getCachedAuthNToken()
    if (authNToken) {
      const { expires = null } = authNToken
      return expires
    }
    return null
  }

  getAuthZTTL(resource: ResourceID): number | null {
    if (!resource) return null
    const authZToken = this._getCachedAuthZToken(resource)
    if (authZToken) {
      const { expires = null } = authZToken
      return expires
    }
    return null
  }

  // TODO: Determine when `encrypted` is true, and how that affects data.
  // TODO: Determine what to do with non-simple data, e.g. `channelID` and
  // `zip` are arrays of strings, and `maxRating` is a dictionary!
  // TODO: Determine what the possible values are for undocumented metadata,
  // i.e. `inHome`, `language`, `onNet`.
  // TODO: Ensure documented booleans are not strings, i.e. `allowMirroring`
  // and `hba_status`.
  /**
     * ### Request a single metadata value
     */
  async requestUserMetadataValue(key: MetadataKey): Promise<MetadataValue> {
    if (!key) return null
    const userMetadata = await this._updateCachedUserMetadata()
    const { data } = userMetadata
    const value = isObject(data) && !Array.isArray(data) ? data[key] : null
    return value
  }

  private async _call({
    path,
    method = GET,
    headers = {},
    searchParams = {},
    body,
    ...rest
  }: ClientlessNetworkCallOptions): Promise<Response> {
    if (!path) throw new Error('[ClientlessAPI:call] A "path" is required.')

    let url = `${this._adobeOrigin}${path}`

    const clientTokenRequired = !this._accessToken && !path.startsWith('/o/client/')
    // 30m before expiry get a new token
    const clientTokenExpired = this._accessTokenExpiry !== undefined && Date.now() > this._accessTokenExpiry - (30 * MINUTE)
    if (clientTokenRequired || clientTokenExpired) {
      this._accessToken = undefined
      this._accessTokenExpiry = undefined
      await this.requestClientAccessToken()
    }
    if (this._accessToken) {
      headers['Authorization'] = `Bearer ${this._accessToken}`
    }

    const newOpts = {
      method,
      headers,
      body,
      ...rest,
    }

    // If an ExperienceCloudId (ECID) is provided, send it on *all* API calls.
    if (this._ecid) {
      searchParams['ap_vi'] = this._ecid
    }

    if (Object.keys(searchParams).length > 0) {
      url = appendQuery(url, searchParams)
    }

    const res = await fetch(url, newOpts)
    if (!res.ok) throw new HTTPError(res)
    return res
  }

  private _getDeviceIdParams(): ClientlessDeviceIdParams {
    const deviceIdParams: ClientlessDeviceIdParams = {
      deviceId: this._hashedDeviceId,
      deviceType: this._deviceType,
    }
    return deviceIdParams
  }

  private _clearCachedData(): void {
    this._hashedUserId = null
    this._cachedMvpdId = null
    this._userMetadata = undefined
    this._authNToken = undefined
    this._authZTokens.clear()
  }

  private _getBase64DeviceInfo() {
    try {
      return window.btoa(JSON.stringify(this._deviceInfo))
    } catch {
      return ''
    }
  }

  private _getDeviceInfoHeaders() {
    const headers: Record<string, string> = {}
    const base64DeviceInfo = this._getBase64DeviceInfo()
    if (base64DeviceInfo) {
      headers['X-Device-Info'] = encodeURIComponent(base64DeviceInfo)
    }
    return headers
  }

  private _storeAuthNToken(authNToken: ClientlessAuthNToken) {
    this._authNToken = authNToken
    const { userId, mvpd } = authNToken
    this._hashedUserId = userId || null
    this._cachedMvpdId = mvpd || null
  }

  private _storeAuthZToken(resource: ResourceID, authZToken: ClientlessAuthZToken) {
    this._authZTokens.forEach(deleteExpiredAuthZToken)
    this._authZTokens.set(resource, authZToken)
  }

  private _getCachedAuthNToken(): ClientlessAuthNToken | undefined {
    let authNToken: ClientlessAuthNToken | undefined = this._authNToken
    if (authNToken) {
      const { expires } = authNToken
      if (!expires || Date.now() > expires) {
        authNToken = this._authNToken = undefined
      }
    }
    return authNToken
  }

  private _getCachedAuthZToken(resource: ResourceID): ClientlessAuthZToken | undefined {
    let authZToken: ClientlessAuthZToken | undefined = this._authZTokens.get(resource)
    if (authZToken) {
      const { expires } = authZToken
      const number = Number(expires)
      if (!number || Date.now() > number) {
        this._authZTokens.delete(resource)
        authZToken = undefined
      }
    }
    return authZToken
  }

  private async _updateCachedUserMetadata(): Promise<ClientlessUserMetadata> {
    if (
      !this._userMetadata ||
            !this._userMetadataUpdated ||
            Math.abs(Date.now() - this._userMetadataUpdated) > 12 * HOUR
    ) {
      const userMetadata: ClientlessUserMetadata = await this.requestUserMetadata()
      this._userMetadata = userMetadata
      const { updated = Date.now() } = userMetadata
      this._userMetadataUpdated = updated
    }
    return this._userMetadata
  }
}

const isObject = (item: unknown) => typeof item === 'object' && item !== null

const deleteExpiredAuthZToken = (val: ClientlessAuthZToken, key: ResourceID, map: Map<ResourceID, ClientlessAuthZToken>) => {
  if (isObject(val)) {
    const { expires } = val
    const number = Number(expires)
    if (!number || !isFinite(number) || Date.now() >= number) {
      map.delete(key)
    }
  }
}

const ensureExpiresValue = (expires: string): number => {
  const number = Number(expires)
  if (!isNaN(number) && number && isFinite(number)) return number
  return Date.now() + 5 * MINUTE
}

const normalizeAdobeConfigItem = (item: unknown): any => {
  if (Array.isArray(item)) {
    return item.map(normalizeAdobeConfigItem)
  }
  if (isObject(item)) {
    const items = item as Record<string, unknown>
    return 'value' in items
      ? items['value']
      : Object.keys(items).reduce((memo: Record<string, unknown>, key) => {
        memo[key] = normalizeAdobeConfigItem(items[key])
        return memo
      }, {})
  }
  return item
}

const adobeConfigToMvpdList = (adobeConfig: Record<string, unknown>) => {
  try {
    const normalizedConfig = normalizeAdobeConfigItem(adobeConfig)
    return normalizedConfig.requestor.mvpds.mvpd
  } catch {
    // this will never fail, right?
  }
}
