import {
  buildMvpdConfigInvalidDataError,
  buildMvpdConfigJsonParseError,
  buildMvpdConfigRequestError,
} from './ClientlessAuthErrors'

export type ImageContext = 'primary' | 'cobranding';
export type CobrandImageColor = 'black' | 'gray' | 'white';

export interface ImageTemplateBuilderData {
    readonly context: ImageContext;
    readonly providerId: string;
    readonly color?: CobrandImageColor;
    readonly width: number;
    readonly height: number;
}

export interface ProviderCoreProps {
    [key: string]: any;

    /** The 2-letter country code that the [[Provider]] serves, i.e. 'US' or 'CA' */
    country: string;

    /** The human-readable display name of the [[Provider]], e.g. "AT&T" */
    displayName: string;

    /** The Adobe MVPD ID for the [[Provider]], e.g. "ATT" */
    id: string;

    /**
     * Whether the [[Provider]] should be visible in the Picker.
     *
     * @remarks
     * "TempPass*" providers are valid providers, but aren't visible in the Picker.
     */
    visible?: boolean;

    /**
     * Serves as both a flag that the [[Provider]] should be in the primary/logo view of the Picker,
     * and provides the index for its order relative to other primary Providers' `primaryOrder` value.
     * If not present, the [[Provider]] will only be selectable from the list-of-names view in the Picker.
     */
    primaryOrder?: number;
}

export type PlatformMetadata = {
    /**
     * Provides white-listing capability to enable/disable a [[Provider]].
     *
     * @remarks
     * With the dynamic TVE Manager service that generates the MVPD Config, this prop is unneeded.
     * If the Provider is to be disabled, it could just as easily be removed from the MVPD Config output.
     */
    enabled?: boolean;

    /**
     * Originally expected to determine the method of logging into the [[Provider]].
     *
     * @remarks
     * Support for this property is _not_ currently implemented. Vestigial prop from an old spec?
     */
    loginMethod?: string;

    /**
     * A URL for sending the user to the [[Provider]]'s web site
     */
    callbackUrl?: string;
};
export type Provider = ProviderCoreProps & PlatformMetadata;

export interface MVPDConfiguration {
    brand: string;
    cobrandingImgTemplate: string;
    primaryImgTemplate: string;
    mvpd: Provider[];
}

const templateTokenRegEx = /\$\{([^}]*)\}/g
const tokenReplacer = (tokenRegEx: RegExp) => (template: string, data: Record<string, any>) =>
  template.replace(tokenRegEx, (_: string, key: string) => {
    let value = null
    if (key in data) value = String(data[key])
    return value != null ? value : ''
  })
/**
 * Interpolate = replace template tokens
 *
 * @example
 * ```
 * interpolate(
 *   "String with ${token} substrings, missing ${tokens} are removed",
 *   {
 *     token: "replacement"
 *   }
 * )
 * ```
 */
export const interpolate = tokenReplacer(templateTokenRegEx)

export class MVPDConfigAPI {
  config!: MVPDConfiguration

  constructor(
    public mvpdConfigUrl: string | URL,
    public appId: string,
    public platform: string | 'web' | 'webOS' | 'samsungtv'
  ) {}

  /**
   * Fetches the WarnerMedia MVPD Config, functions as both a white-list and provides additional data.
   */
  async fetchConfig(): Promise<MVPDConfiguration> {
    // Only fetch the config once
    if (this.config) return Promise.resolve(this.config)

    let data

    try {
      let response

      // Make request
      try {
        const appIdHeader = 'app-id'
        const headers = new Headers()
        headers.set(appIdHeader, this.appId)

        const request = new Request(this.mvpdConfigUrl.toString(), { headers })
        response = await fetch(request)
      } catch (requestError) {
        throw buildMvpdConfigRequestError(requestError as Error)
      }

      // Process response
      if (!response.ok) {
        throw buildMvpdConfigRequestError(response)
      }
      try {
        data = await response.json()
      } catch (responseError) {
        throw buildMvpdConfigJsonParseError()
      }
    } catch (error) {
      return Promise.reject(error)
    }

    this.config = data as MVPDConfiguration
    return this.config
  }

  /**
   * Construct an image URL string from [[ImageTemplateBuilderData]]
   *
   * @param {ImageTemplateBuilderData} imageData The required parameters to construct an image URL
   * @returns {Promise<ImageURLDataResponse>} The `imageURL` is inside, if successful
   */
  buildImageURLString(imageData: ImageTemplateBuilderData): string {
    if (!this.config) {
      throw buildMvpdConfigInvalidDataError()
    }

    const template: string = imageData.context === 'primary' ? this.config.primaryImgTemplate : this.config.cobrandingImgTemplate

    return interpolate(template, imageData)
  }
}
