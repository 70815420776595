/**
 * ClientlessAuthErrors
 */

import {
  AuthError,
  AuthErrorBuilder,
  AuthErrorCategory,
  AuthErrorDomain,
  AuthErrorMetadata,
  AuthErrorNumeral,
  AuthErrorSeverity,
} from './AuthError'
import { ClientRegistrationError } from './ClientlessAuthTypes'

type HttpStatusCodeToErrorNumeralMap = Record<number, AuthErrorNumeral>;

const isString = (value: unknown) => typeof value === 'string'

/** @hidden */
export class HTTPError extends Error {
  static is(object: unknown): object is HTTPError {
    const httpError = object as HTTPError
    return (
      httpError != null &&
            typeof httpError === 'object' &&
            'name' in httpError &&
            httpError.name === 'HTTPError' &&
            'response' in httpError &&
            httpError.response != null
    )
  }

  response: Response

  constructor(response: Response) {
    super(`${response.status}`)
    this.name = 'HTTPError'
    this.response = response
  }
}

/** @hidden */
export class ClientlessAuthErrorBuilder extends AuthErrorBuilder {
  constructor() {
    super()
    this.withDomain(AuthErrorDomain.Adobe)
  }
}

const {
  ClientlessUnknownError,
  ClientlessAPIBadRequest,
  ClientlessAPIUnexpectedResponseCode,
  ClientlessMissingSoftwareStatement,
  ClientlessMissingRequestorId,

  ClientlessClientRegistrationServiceException,
  ClientlessClientRegistrationInvalidResponseBody,

  ClientlessClientAccessTokenServiceException,
  ClientlessClientAccessTokenInvalidResponseBody,

  ClientlessConfigServiceException,
  ClientlessConfigInvalidResponseBody,
  ClientlessConfigParseError,

  ClientlessRegCodeServiceException,
  ClientlessRegCodeInvalidResponseBody,
  ClientlessRegCodeNotFound,

  ClientlessCheckAuthNServiceException,
  ClientlessCheckAuthNForbidden,

  ClientlessAuthNTokenServiceException,
  ClientlessAuthNTokenParseError,
  ClientlessAuthNTokenNotFound,
  ClientlessAuthNTokenExpired,
  ClientlessInvalidClient,
  ClientlessAccessDenied,

  ClientlessPreauthorizeServiceException,
  ClientlessPreauthorizeNoResourcesProvided,
  ClientlessPreauthorizeNotAuthorized,
  ClientlessPreauthorizeExpired,
  ClientlessPreauthorizeNotAuthenticated,

  ClientlessAuthorizeServiceException,
  ClientlessAuthorizeNotAuthorized,

  ClientlessAuthZTokenServiceException,
  ClientlessAuthZTokenParseError,
  ClientlessAuthZTokenNotAuthenticated,
  ClientlessAuthZTokenExpired,
  ClientlessAuthZTokenNotAuthorized,

  ClientlessShortMediaTokenServiceException,
  ClientlessShortMediaTokenParseError,
  ClientlessShortMediaTokenNotAuthorized,

  ClientlessUserMetadataServiceException,
  ClientlessUserMetadataParseError,
  ClientlessUserMetadataNotFound,
  ClientlessUserMetadataExpiredToken,
} = AuthErrorNumeral

const {
  Initialization,
  Config,
  Authentication,
  Authorization,
  Metadata,
  Logout,
} = AuthErrorCategory

const numeralToStringMap: Record<string, string> = {
  [ClientlessUnknownError]: 'Unknown Error',
  [ClientlessAPIBadRequest]: 'Bad Request',
  [ClientlessAPIUnexpectedResponseCode]: 'Unexpected Response Code',
  [ClientlessMissingSoftwareStatement]: 'Missing Software Statement',
  [ClientlessMissingRequestorId]: 'Missing Requestor ID',

  [ClientlessClientRegistrationServiceException]: 'Client Registration Service Exception',
  [ClientlessClientRegistrationInvalidResponseBody]: 'Invalid Client Registration Response Body',

  [ClientlessClientAccessTokenServiceException]: 'Client Access Token Service Exception',
  [ClientlessClientAccessTokenInvalidResponseBody]: 'Invalid Client Access Token Response Body',

  [ClientlessConfigServiceException]: 'Config Service Exception',
  [ClientlessConfigInvalidResponseBody]: 'Invalid Config Response Body',
  [ClientlessConfigParseError]: 'Config Parse Error',

  [ClientlessRegCodeServiceException]: 'Reg Code Service Exception',
  [ClientlessRegCodeInvalidResponseBody]: 'Invalid Reg Code Response Body',
  [ClientlessRegCodeNotFound]: 'Reg Code Not Found',

  [ClientlessCheckAuthNServiceException]: 'Check AuthN Status Service Exception',
  [ClientlessCheckAuthNForbidden]: 'Check AuthN Status Forbidden',

  [ClientlessAuthNTokenServiceException]: 'Authentication Token Service Exception',
  [ClientlessAuthNTokenParseError]: 'Authentication Token Parse Error',
  [ClientlessAuthNTokenNotFound]: 'Authentication Token Not Found',
  [ClientlessAuthNTokenExpired]: 'Authentication Token Expired',

  [ClientlessPreauthorizeServiceException]: 'Preauthorize Service Exception',
  [ClientlessPreauthorizeNoResourcesProvided]: 'No Resources Provided',
  [ClientlessPreauthorizeNotAuthorized]: 'Not Authorized',
  [ClientlessPreauthorizeExpired]: 'Expired',
  [ClientlessPreauthorizeNotAuthenticated]: 'Not Authenticated',

  [ClientlessAuthorizeServiceException]: 'Authorize Service Exception',
  [ClientlessAuthorizeNotAuthorized]: 'Not Authorized',

  [ClientlessAuthZTokenServiceException]: 'Authorization Token Service Exception',
  [ClientlessAuthZTokenParseError]: 'Authorization Token Parse Error',
  [ClientlessAuthZTokenNotAuthenticated]: 'Not Authenticated',
  [ClientlessAuthZTokenExpired]: 'Authorization Token Expired',
  [ClientlessAuthZTokenNotAuthorized]: 'Not Authorized',

  [ClientlessShortMediaTokenServiceException]: 'Short Media Token Service Exception',
  [ClientlessShortMediaTokenParseError]: 'Short Media Token Parse Error',
  [ClientlessShortMediaTokenNotAuthorized]: 'Not Authorized',

  [ClientlessUserMetadataServiceException]: 'User Metadata Service Exception',
  [ClientlessUserMetadataParseError]: 'User Metadata Parse Error',
  [ClientlessUserMetadataNotFound]: 'User Metadata Not Found',
  [ClientlessUserMetadataExpiredToken]: 'Expired Token',
}

const describeNumeral = (numeral: AuthErrorNumeral): string => numeralToStringMap[numeral] ?? 'Unknown error'
const fallbackErrorString = 'Unknown failure'

export const buildMvpdConfigRequestError = (requestError: Error | Response): AuthError => {
  const error = new AuthErrorBuilder()
    .withSeverity(AuthErrorSeverity.Fatal)
    .withMessage('MVPD Config request failure')
    .withCategory(AuthErrorCategory.Config)
    .withDomain(AuthErrorDomain.Shared)
    .withNumeral(AuthErrorNumeral.MvpdConfigRequestFailure)

  if (requestError instanceof Response) {
    error.withSubErrorCode(`${requestError.status} ${requestError.statusText}`)
    error.withSubErrorMessage(requestError.url)
  }

  return error.build()
}

export const buildMvpdConfigJsonParseError = (): AuthError => {
  return new AuthErrorBuilder()
    .withSeverity(AuthErrorSeverity.Fatal)
    .withMessage('MVPD Config failed to parse as JSON')
    .withCategory(AuthErrorCategory.Config)
    .withDomain(AuthErrorDomain.Shared)
    .withNumeral(AuthErrorNumeral.MvpdConfigResponseFailure)
    .build()
}

export const buildMvpdConfigInvalidDataError = (): AuthError => {
  return new AuthErrorBuilder()
    .withSeverity(AuthErrorSeverity.Fatal)
    .withMessage('MVPD Config has no data or an invalid schema')
    .withCategory(AuthErrorCategory.Config)
    .withDomain(AuthErrorDomain.Shared)
    .withNumeral(AuthErrorNumeral.MvpdConfigResponseFailure)
    .build()
}

/** @hidden */
export const createMissingRequestorIdError = (): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage('Missing Requestor ID (aka "brand")')
    .withCategory(Initialization)
    .withNumeral(ClientlessMissingRequestorId)
    .build()
  return authError
}

/** @hidden */
export const createMissingSoftwareStatementError = (): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage('Missing software statement')
    .withCategory(Initialization)
    .withNumeral(ClientlessMissingSoftwareStatement)
    .build()
  return authError
}

/** @hidden */
export const createClientRegistrationNetworkError = (
  networkError: unknown,
  _data?: ClientRegistrationError
): AuthError => {
  let message = String(networkError) || fallbackErrorString
  let numeral = ClientlessUnknownError
  if (HTTPError.is(networkError)) {
    const { response } = networkError
    const { status } = response
    const codeToNumeralMap: HttpStatusCodeToErrorNumeralMap = {
      400: ClientlessAPIBadRequest,
    }
    numeral = codeToNumeralMap[status] || ClientlessClientRegistrationServiceException
    message = `${status} ${describeNumeral(numeral)}`
  }
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(message)
    .withCategory(Initialization)
    .withNumeral(numeral)
    .build()
  return authError
}

/** @hidden */
export const createClientRegistrationBodyError = (bodyError: unknown): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(String(bodyError) || 'Client registration response data JSON parse failure')
    .withCategory(Initialization)
    .withNumeral(ClientlessClientRegistrationInvalidResponseBody)
    .build()
  return authError
}

/** @hidden */
export const createClientRegistrationInitError = (): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage('Client ID and secret are required before requesting client token')
    .withCategory(Initialization)
    .withDomain(AuthErrorDomain.Runtime)
    .withNumeral(AuthErrorNumeral.AdobeInitializationFailure)
    .build()
  return authError
}

/** @hidden */
export const createClientAccessTokenNetworkError = (
  networkError: unknown,
  _data?: ClientRegistrationError
): AuthError => {
  let message = String(networkError) || fallbackErrorString
  let numeral = ClientlessUnknownError
  if (HTTPError.is(networkError)) {
    const { response } = networkError
    const { status } = response
    const codeToNumeralMap: HttpStatusCodeToErrorNumeralMap = {
      400: ClientlessAPIBadRequest,
      401: ClientlessAccessDenied,
      404: ClientlessRegCodeNotFound,
    }
    numeral = codeToNumeralMap[status] || ClientlessClientAccessTokenServiceException
    message = `${status} ${describeNumeral(numeral)}`
  }
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(message)
    .withCategory(Initialization)
    .withNumeral(numeral)
    .build()
  return authError
}

/** @hidden */
export const createClientAccessTokenBodyError = (bodyError: unknown): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(String(bodyError) || 'Client access token response data JSON parse failure')
    .withCategory(Initialization)
    .withNumeral(ClientlessClientAccessTokenInvalidResponseBody)
    .build()
  return authError
}

/** @hidden */
export const createConfigNetworkError = (networkError: unknown): AuthError => {
  let message = String(networkError) || fallbackErrorString
  let numeral = ClientlessUnknownError
  if (HTTPError.is(networkError)) {
    const { response } = networkError
    const { status } = response
    numeral = ClientlessConfigServiceException
    message = `${status} ${describeNumeral(numeral)}`
  }
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(message)
    .withCategory(Config)
    .withNumeral(numeral)
    .build()
  return authError
}

/** @hidden */
export const createConfigBodyError = (res: Response, bodyError: unknown): AuthError => {
  const { status, url } = res
  const httpStatus = `${status}`
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(String(bodyError) || 'Failed to obtain text body')
    .withCategory(Authentication)
    .withNumeral(ClientlessConfigInvalidResponseBody)
    .withSubErrorCode(httpStatus)
    .withSubErrorMessage(url)
    .build()
  return authError
}

/** @hidden */
export const createConfigParseError = (parseError: unknown): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(String(parseError) || 'Config parse failure')
    .withCategory(Authentication)
    .withNumeral(ClientlessConfigParseError)
    .build()
  return authError
}

/** @hidden */
export const createRegCodeNetworkError = (networkError: unknown): AuthError => {
  let message = String(networkError) || fallbackErrorString
  let numeral = ClientlessUnknownError
  if (HTTPError.is(networkError)) {
    const { response } = networkError
    const { status } = response
    const codeToNumeralMap: HttpStatusCodeToErrorNumeralMap = {
      400: ClientlessAPIBadRequest,
      401: ClientlessAccessDenied,
      404: ClientlessRegCodeNotFound,
    }
    numeral = codeToNumeralMap[status] || ClientlessRegCodeServiceException
    message = `${status} ${describeNumeral(numeral)}`
  }
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(message)
    .withCategory(Authentication)
    .withNumeral(numeral)
    .build()
  return authError
}

/** @hidden */
export const createRegCodeParseError = (parseError: unknown): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(String(parseError) || 'Regcode response data JSON parse failure')
    .withCategory(Authentication)
    .withNumeral(ClientlessRegCodeInvalidResponseBody)
    .build()
  return authError
}

/** @hidden */
export const createCheckAuthNTokenNetworkError = (networkError: unknown): AuthError => {
  let message = String(networkError) || fallbackErrorString
  let numeral = ClientlessUnknownError
  if (HTTPError.is(networkError)) {
    const { response } = networkError
    const { status } = response
    const codeToNumeralMap: HttpStatusCodeToErrorNumeralMap = {
      400: ClientlessAPIBadRequest,
      403: ClientlessCheckAuthNForbidden,
    }
    numeral = codeToNumeralMap[status] || ClientlessCheckAuthNServiceException
    message = `${status} ${describeNumeral(numeral)}`
  }
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(message)
    .withCategory(Authentication)
    .withNumeral(numeral)
    .build()
  return authError
}

/** @hidden */
export const createAuthNTokenNetworkError = (networkError: unknown): AuthError => {
  let message = String(networkError) || fallbackErrorString
  let numeral = ClientlessUnknownError
  if (HTTPError.is(networkError)) {
    const { response } = networkError
    const { status } = response
    const codeToNumeralMap: HttpStatusCodeToErrorNumeralMap = {
      400: ClientlessAPIBadRequest,
      401: ClientlessAccessDenied,
      403: ClientlessInvalidClient,
      404: ClientlessAuthNTokenNotFound,
      410: ClientlessAuthNTokenExpired,
    }
    numeral = codeToNumeralMap[status] || ClientlessAuthNTokenServiceException
    message = `${status} ${describeNumeral(numeral)}`
  }
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(message)
    .withCategory(Authentication)
    .withNumeral(numeral)
    .build()
  return authError
}

/** @hidden */
export const createAuthNTokenParseError = (parseError: unknown): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(String(parseError) || 'AuthN token response data JSON parse failure')
    .withCategory(Authentication)
    .withNumeral(ClientlessAuthNTokenParseError)
    .build()
  return authError
}

/** @hidden */
export const createPreauthorizeInvalidInputError = (): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage('No resources provided')
    .withCategory(Authorization)
    .withNumeral(ClientlessPreauthorizeNoResourcesProvided)
    .build()
  return authError
}

/** @hidden */
export const createPreauthorizeNetworkError = (networkError: unknown): AuthError => {
  let message = String(networkError) || fallbackErrorString
  let numeral = ClientlessUnknownError
  if (HTTPError.is(networkError)) {
    const { response } = networkError
    const { status } = response
    const codeToNumeralMap: HttpStatusCodeToErrorNumeralMap = {
      400: ClientlessAPIBadRequest,
      401: ClientlessPreauthorizeNotAuthenticated,
      404: ClientlessPreauthorizeNotAuthorized,
      410: ClientlessPreauthorizeExpired,
      412: ClientlessPreauthorizeNotAuthenticated,
    }
    numeral = codeToNumeralMap[status] || ClientlessPreauthorizeServiceException
    message = `${status} ${describeNumeral(numeral)}`
  }
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(message)
    .withCategory(Authorization)
    .withNumeral(numeral)
    .build()
  return authError
}

/** @hidden */
export const createPreauthorizeResponseParseError = (parseError: unknown): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(String(parseError) || 'Preauthorize response data JSON parse failure')
    .withCategory(Authorization)
    .withNumeral(ClientlessAuthZTokenParseError)
    .build()
  return authError
}

/** @hidden */
const createAuthError = (message: string, numeral: AuthErrorNumeral, metadata?: AuthErrorMetadata) : AuthError => {
  const authErrorBuilder = new ClientlessAuthErrorBuilder()
    .withMessage(message)
    .withCategory(Authorization)
    .withNumeral(numeral)

  if (metadata) {
    authErrorBuilder.withMetadata(metadata)
  }

  return authErrorBuilder.build()
}

/** @hidden */
export const requestAuthorizeNetworkError = async (networkError: unknown): Promise<AuthError> => {

  let message = String(networkError) || fallbackErrorString
  let numeral = ClientlessUnknownError

  if (HTTPError.is(networkError)) {
    const { response } = networkError
    const { status } = response
    const codeToNumeralMap: HttpStatusCodeToErrorNumeralMap = {
      400: ClientlessAPIBadRequest,
      403: ClientlessAuthorizeNotAuthorized,
    }
    numeral = codeToNumeralMap[status] || ClientlessAuthorizeServiceException
    message = `${status} ${describeNumeral(numeral)}`

    return await response.clone().json()
      .then(( { message: subErrorCode, details: subErrorMessage } ) => {
        const metadata: AuthErrorMetadata = {}
        if (isString(subErrorCode)) metadata.subErrorCode = subErrorCode
        if (isString(subErrorMessage)) metadata.subErrorMessage = subErrorMessage
        return createAuthError(message, numeral, metadata)
      })
      .catch(() => {
        return createAuthError(message, numeral)
      })
  }

  return new Promise((resolve) => {
    resolve( createAuthError(message, numeral) )
  })

}

/** @hidden */
export const createAuthorizeResponseParseError = (parseError: unknown): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(String(parseError) || 'Authorize response data JSON parse failure')
    .withCategory(Authorization)
    .withNumeral(ClientlessAuthZTokenParseError)
    .build()
  return authError
}

/** @hidden */
export const createAuthZTokenNetworkError = (networkError: unknown): AuthError => {
  let message = String(networkError) || fallbackErrorString
  let numeral = ClientlessUnknownError
  if (HTTPError.is(networkError)) {
    const { response } = networkError
    const { status } = response
    const codeToNumeralMap: HttpStatusCodeToErrorNumeralMap = {
      400: ClientlessAPIBadRequest,
      404: ClientlessAuthZTokenNotAuthorized,
      410: ClientlessAuthZTokenExpired,
      412: ClientlessAuthZTokenNotAuthenticated,
    }
    numeral = codeToNumeralMap[status] || ClientlessAuthZTokenServiceException
    message = `${status} ${describeNumeral(numeral)}`
  }
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(message)
    .withCategory(Authorization)
    .withNumeral(numeral)
    .build()
  return authError
}

/** @hidden */
export const createAuthZTokenParseError = (parseError: unknown): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(String(parseError) || 'AuthZ token response data JSON parse failure')
    .withCategory(Authorization)
    .withNumeral(ClientlessAuthZTokenParseError)
    .build()
  return authError
}

/** @hidden */
export const createShortMediaTokenNetworkError = (networkError: unknown): AuthError => {
  let message = String(networkError) || fallbackErrorString
  let numeral = ClientlessUnknownError
  if (HTTPError.is(networkError)) {
    const { response } = networkError
    const { status } = response
    const codeToNumeralMap: HttpStatusCodeToErrorNumeralMap = {
      400: ClientlessAPIBadRequest,
      403: ClientlessShortMediaTokenNotAuthorized,
    }
    numeral = codeToNumeralMap[status] || ClientlessShortMediaTokenServiceException
    message = `${status} ${describeNumeral(numeral)}`
  }
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(message)
    .withCategory(Authorization)
    .withNumeral(numeral)
    .build()
  return authError
}

/** @hidden */
export const createShortMediaTokenParseError = (parseError: unknown): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(String(parseError) || 'AuthZ token response data JSON parse failure')
    .withCategory(Authorization)
    .withNumeral(ClientlessShortMediaTokenParseError)
    .build()
  return authError
}

/** @hidden */
export const createUserMetadataNetworkError = (networkError: unknown): AuthError => {
  let message = String(networkError) || fallbackErrorString
  let numeral = ClientlessUnknownError
  if (HTTPError.is(networkError)) {
    const { response } = networkError
    const { status } = response
    const codeToNumeralMap: HttpStatusCodeToErrorNumeralMap = {
      400: ClientlessAPIBadRequest,
      404: ClientlessUserMetadataNotFound,
      412: ClientlessUserMetadataExpiredToken,
    }
    numeral = codeToNumeralMap[status] || ClientlessUserMetadataServiceException
    message = `${status} ${describeNumeral(numeral)}`
  }
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(message)
    .withCategory(Metadata)
    .withNumeral(numeral)
    .build()
  return authError
}

/** @hidden */
export const createUserMetadataParseError = (parseError: unknown): AuthError => {
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(String(parseError) || 'User metadata response data JSON parse failure')
    .withCategory(Metadata)
    .withNumeral(ClientlessUserMetadataParseError)
    .build()
  return authError
}

/** @hidden */
export const createLogoutNetworkError = (networkError: unknown): AuthError => {
  let message = String(networkError) || fallbackErrorString
  let numeral = ClientlessUnknownError
  if (HTTPError.is(networkError)) {
    const { response } = networkError
    const { status } = response
    const codeToNumeralMap: HttpStatusCodeToErrorNumeralMap = {
      400: ClientlessAPIBadRequest,
    }
    numeral = codeToNumeralMap[status] || ClientlessAPIUnexpectedResponseCode
    message = `${status} ${describeNumeral(numeral)}`
  }
  const authError = new ClientlessAuthErrorBuilder()
    .withMessage(message)
    .withCategory(Logout)
    .withNumeral(numeral)
    .build()
  return authError
}
