/**
 * AuthConfiguration
 */
import {
  AuthError,
  AuthErrorBuilder,
  AuthErrorCategory,
  AuthErrorDomain,
  AuthErrorNumeral,
  AuthErrorPlatform,
  AuthErrorSeverity,
} from './AuthError'
import { DefaultDeviceInfo, DeviceInfo } from './DeviceInfo'

/**
 * The AuthConfigBuilder
 *
 * Helper class to create various Auth configs.
 *
 * @public
 */
export class AuthConfigBuilder {
  private _platform?: string
  private _mvpdConfigURL?: URL | string
  private _serviceAppId?: JWT
  private _softwareStatement?: JWT
  private _redirectUri?: string
  private _brand?: string
  private _ecid?: string
  private _environment?: Environment
  private _registrationURL?: URL | string
  private _pollingInterval?: number
  private _deviceInfo?: Partial<DeviceInfo>

  /**
   * (Required) `platform` is used to pluck data from MVPDConfig, e.g. `android`, `androidtv`, `firetv`, `ios`, `roku`, `tvos`, `web` (note: platform support may vary by brand)
   */
  withPlatform(platform: string): this {
    this._platform = platform.toLowerCase()
    return this
  }

  /**
   * (Required) `mvpdConfigURL` is the allowlist of MVPDs (TV service providers)
   */
  withMVPDConfigURL(url: URL | string): this {
    this._mvpdConfigURL = url
    return this
  }

  /**
   * (Required) The value is provided to services for MVPDConfig, Tokens, SBP, etc.
   */
  withServiceAppId(serviceAppId: JWT): this {
    this._serviceAppId = serviceAppId
    return this
  }

  /**
   * Adobe softwareStatement
   */
  withSoftwareStatement(softwareStatement: JWT): this {
    this._softwareStatement = softwareStatement
    return this
  }

  /**
   * (Required)
   */
  withBrand(brand: string): this {
    this._brand = brand
    return this
  }

  /**
   * (Optional) Adobe Cloud ID
   */
  withExperienceCloudId(ecid: string): this {
    this._ecid = ecid
    return this
  }

  /**
   * (Optional) Defaults to 'production'
   */
  withEnvironment(environment: Environment): this {
    this._environment = environment
    return this
  }

  /**
   * The URL of the second screen activation page to which users will be directed in order to login
   */
  withRegistrationURL(url: URL | string): this {
    this._registrationURL = url
    return this
  }

  /**
   * (Optional) Defaults to checking authN status every 30 seconds
   */
  withPollingInterval(interval: number): this {
    this._pollingInterval = interval
    return this
  }

  /**
   * Specify device information (e.g. "model", "osName") when registering client with REST API
   */
  withDeviceInfo(deviceInfo: Partial<DeviceInfo>): this {
    this._deviceInfo = deviceInfo
    return this
  }

  /**
   * (Optional) Specify redirectUri for DCR
   * The URI that the application uses for completing the authentication flow.
   * (This sounds like something AccessEnabler does, but our Clientless impl wouldn't.
   * Post-login with AccessEnabler, native iOS/Android load an invalid URL in the webview.
   * But we don't have a webview in our Clientless impl. Our login flow occurs via an
   * activation web page on a 2nd screen.)
   */
  withRedirectUri(redirectUri: string): this {
    this._redirectUri = redirectUri
    return this
  }

  /**
   * @public
   */
  public build(): AuthConfiguration {
    if (!this._softwareStatement) throw configError('softwareStatement')
    if (!this._platform) throw configError('platform')
    if (!this._mvpdConfigURL) throw configError('mvpdConfigURL')
    if (!this._brand) throw configError('brand')
    if (!this._serviceAppId) throw configError('serviceAppId')

    const mergedDeviceInfo = {
      ...DefaultDeviceInfo.get(),
      ...this._deviceInfo,
    }

    return {
      platform: this._platform,
      mvpdConfigURL: this._mvpdConfigURL,
      serviceAppId: this._serviceAppId,
      softwareStatement: this._softwareStatement || '',
      brand: this._brand,
      ecid: this._ecid,
      environment: this._environment || EnvironmentName.PRODUCTION,
      registrationURL: this._registrationURL,
      pollingInterval: this._pollingInterval,
      deviceInfo: mergedDeviceInfo,
      redirectUri: this._redirectUri,
    }
  }
}

/** The param provided to the Auth Block target to create an [[IAuth]] instance */
export interface AuthConfiguration {
  /** Platform by which to filter supported providers from MVPDConfig, e.g. `android`, `androidtv`, `firetv`, `ios`, `roku`, `tvos`, `web` (note: platform support may vary by brand) */
  platform: string;

  /** The URL to the MVPDConfig */
  mvpdConfigURL: URL | string;

  /** The Service JWT App Id */
  serviceAppId: JWT;

  /** The JWT Software statement */
  softwareStatement: JWT;

  /** (Optional) The URI that the application uses for completing the authentication flow */
  redirectUri?: string;

  /** The brand */
  brand: string;

  /** The URL to present if user selects 'help' in the picker */
  helpURL?: URL | string;

  /** The Adobe Experience Cloud Id */
  ecid?: string;

  /** The API environment */
  environment: Environment;

  /** The Second Screen Registration URL to enter a Registration Code */
  registrationURL?: URL | string;

  /** interval (in seconds) to poll for authN status; default = 30secs; time-out = 15min */
  pollingInterval?: number;

  /** Device info */
  deviceInfo?: Partial<DeviceInfo>;
}

const configError = (missingRequiredProp: string): AuthError => {
  return new AuthErrorBuilder()
    .withMessage(`missing required property: ${missingRequiredProp}`)
    .withSeverity(AuthErrorSeverity.Fatal)
    .withCategory(AuthErrorCategory.Config)
    .withPlatform(AuthErrorPlatform.Unknown)
    .withDomain(AuthErrorDomain.Shared)
    .withNumeral(AuthErrorNumeral.Unspecified)
    .build()
}

/**
 * A JWT Identifier
 * @see {@link AuthConfiguration.serviceAppId}
 * @see {@link AuthConfiguration.softwareStatement}
 * @see {@link IConfigService.fetchMVPDConfig}
 */
export type JWT = string;

export enum EnvironmentName {
  PRODUCTION = 'production',
  STAGING = 'staging',
}

export type Environment = EnvironmentName;
