/**
 * Clientless Dynamic Client Registration requires
 * information about the device/platform.
 */

export type DeviceInfoPrimaryHardwareType =
    | 'Camera'
    | 'DataCollectionTerminal'
    | 'Desktop'
    | 'EmbeddedNetworkModule'
    | 'eReader'
    | 'GamesConsole'
    | 'GeolocationTracker'
    | 'Glasses'
    | 'MediaPlayer'
    | 'MobilePhone'
    | 'PaymentTerminal'
    | 'PluginModem'
    | 'SetTopBox'
    | 'TV'
    | 'Tablet'
    | 'WirelessHotspot'
    | 'Wristwatch'
    | 'Unknown'
    | string;

export type DeviceInfoOSName =
    | 'Android'
    | 'Chrome OS'
    | 'Linux'
    | 'Mac OS'
    | 'OS X'
    | 'OpenBSD'
    | 'Roku OS'
    | 'Windows'
    | 'iOS'
    | 'tvOS'
    | 'webOS'
    | string;

export type DeviceInfoOSFamily =
    | 'Android'
    | 'BSD'
    | 'Linux'
    | 'PlayStation OS'
    | 'Roku OS'
    | 'Symbian'
    | 'Tizen'
    | 'Windows'
    | 'iOS'
    | 'macOS'
    | 'tvOS'
    | 'webOS'
    | string;

export type DeviceInfoConnectionType = 'WiFi' | 'LAN' | '3G' | string;

export type DeviceInfoConnectionSecure = 'true' | 'false';

export interface DeviceInfo extends Record<string, string | undefined> {
    // ## Mandatory
    // Unique identifier for customer app, e.g. "CNN"
    application: string;
    // The model name, e.g. iPhone, AppleTV
    model: string;
    // The name of the Operating System installed, e.g., Android, webOS
    osName: DeviceInfoOSName;

    // ## Optional

    // Primary hardware type
    primaryHardwareType?: DeviceInfoPrimaryHardwareType;
    // Device version if available
    version?: string;
    // Primary organization creating the device
    manufacturer?: string;
    // The company/organization that provides a device to the market
    vendor?: string;
    // The general group name of the operating system, e.g. BSD, Linux, macOS
    osFamily?: DeviceInfoOSFamily;
    // The supplier of the operating system
    osVendor?: string;
    // The version of the Operating System installed
    osVersion?: string;
    // The physical width of the display
    displayWidth?: string;
    // The physical height of the display
    displayHeight?: string;
    // The pixel density of the display
    displayPpi?: string;
    // The diagonal dimension of the display
    diagonalScreenSize?: string;
    // The port used sending the request
    connectionPort?: string;
    // The type of network connection
    connectionType?: DeviceInfoConnectionType;
    // Whether secure network or public
    connectionSecure?: DeviceInfoConnectionSecure;
}

export class DefaultDeviceInfo {
  static get(): Partial<DeviceInfo> {
    const defaultDeviceInfo: Partial<DeviceInfo> = {}
    Object.assign(defaultDeviceInfo, DefaultDeviceInfo.getNavigatorInfo())
    Object.assign(defaultDeviceInfo, DefaultDeviceInfo.getScreenInfo())
    return defaultDeviceInfo
  }

  static getNavigatorInfo(): Record<string, string> {
    const navigatorInfo: Record<string, string> = {}
    if (!navigator) {
      return navigatorInfo
    }

    if (navigator.userAgent) {
      navigatorInfo['userAgent'] = navigator.userAgent.toString()
    }

    // Connection exists on Chrome 61+, Edge 79+, Firefox 31-32, Opera 48, and never on Safari.
    const connection = (navigator as unknown as { connection?: unknown }).connection
    if (connection) {
      const { type, effectiveType } = connection as Record<string, any>
      switch (type) {
        case 'wifi':
          navigatorInfo['connectionType'] = 'WiFi'
          break
        case 'ethernet':
          navigatorInfo['connectionType'] = 'LAN'
          break
        default:
          if (effectiveType) {
            navigatorInfo['connectionType'] = effectiveType
          }
          break
      }
    }

    return navigatorInfo
  }

  static getScreenInfo(): Record<string, string> {
    const screenInfo: Record<string, string> = {}
    if (typeof screen !== 'undefined') {
      const { width, height } = screen
      if (isFinite(width) && isFinite(height) && width * height > 200) {
        const pixelRatio = (isFinite(devicePixelRatio) && devicePixelRatio) || 1
        const pixelWidth = width * pixelRatio
        const pixelHeight = height * pixelRatio
        const ppi = 96 * pixelRatio
        const horizontal = pixelWidth / ppi
        const vertical = pixelHeight / ppi
        const diagonal = Math.sqrt(horizontal ** 2 + vertical ** 2)
        screenInfo['displayWidth'] = pixelWidth.toFixed(2)
        screenInfo['displayHeight'] = pixelHeight.toFixed(2)
        screenInfo['displayPpi'] = ppi.toFixed(2)
        screenInfo['diagonalScreenSize'] = diagonal.toFixed(2)
      }
    }
    return screenInfo
  }

  constructor() {
    return DefaultDeviceInfo.get()
  }
}
